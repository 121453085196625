import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  Button,
  Switch,
  FormControlLabel,
  Divider,
  Paper,
  Modal,
  Snackbar,
  CircularProgress,
  Container,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import LessonPage from "./LessonPage";
import TopAppBar from "../modules/TopAppBar";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { lessonService } from "../services/lesson.service";
import { LessonInterface, LessonPages } from "../interface/LessonInterface";
import PagePreview from "../Components/Learn/PreviewPage";
let demoLesson = {
  lessonName: "",
  lessonSubtext: "",
  section: "",
  time: "",
};
const LessonDetails = () => {
  const location = useLocation();

  const [isPublished, setIsPublished] = useState(
    location?.state?.lesson?.status === "active" || false
  );

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [lessonDetails, setLessonDetails] = useState<LessonInterface>(
    location.state.lesson ? location.state.lesson : demoLesson
  );

  const navigate = useNavigate();
  const [pages, setPages] = useState<LessonPages[]>([]);
  const [addPagesLoading, setAddPagesLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPageName, setNewPageName] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [currentDeleteLoadingIndex, setCurrentDeleteLoadingIndex] = useState<
    number | null
  >(null);
  const [currentOpenedPageIndex, setCurrentOpenedPageIndex] = useState<
    number | null
  >(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true); // Open the modal
  };

  const handleClose = () => {
    setOpen(false); // Close the modal
  };
  const ref = useRef<HTMLInputElement>(null);
  const handlePublishToggle = () => {
    const newStatus = isPublished ? "draft" : "active";
    setLessonDetails({ ...lessonDetails, status: newStatus });
    setIsPublished(!isPublished);
  };
  const handleAddPage = async () => {
    try {
      if (newPageName.trim()) {
        setAddPagesLoading(true);
        let newPageDetails = {
          pageName: newPageName,
          lesson_id: lessonDetails.uuid,
          pageNumber: pages[pages.length - 1]?.pageNumber
            ? pages[pages.length - 1].pageNumber + 1
            : 1,
        };
        const response = await lessonService.createLessonPages(newPageDetails);
        if (response.success) {
          setSnackbarOpen(true);
          setSnackbarMessage(response.message);
          const updatedPages = [...pages, response.page]; // Assuming response.page contains the new page data
          setPages(updatedPages);
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage(response.message);
        }
      }
    } catch (error: any) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    } finally {
      setAddPagesLoading(false);
    }

    // setPages((prevPages: any) => {
    //   return [
    //     ...prevPages,
    //     {
    //       pageName: newPageName,
    //       lesson_id: lessonDetails.uuid,
    //       pageNumber: pages[pages.length - 1]?.pageNumber
    //         ? pages[pages.length - 1].pageNumber + 1
    //         : 1,
    //     },
    //   ];
    // });
  };
  async function getLessonPages() {
    try {
      setPageLoading(true);
      const response = await lessonService.getLessonPages(lessonDetails.uuid);

      if (response.success) {
        setPages(response.data);
        setSnackbarOpen(true);
        setSnackbarMessage("Pages has been fetched successfully");
      }
    } catch (error: any) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    } finally {
      setPageLoading(false);
    }
  }
  useEffect(() => {
    getLessonPages();
  }, []);
  const handleModalClose = () => {
    setIsModalOpen(false);
    setNewPageName("");
  };

  useEffect(() => {
    console.log("this are pages");
    console.log(pages);
  }, [pages]);
  //// Funciton for updating the lessons ---------------------------------------------------/
  async function updateLessons() {
    try {
      setUpdateLoading(true);
      console.log("here");

      const pageData = pages.map(({ pageContent, ...rest }) => rest);
      console.log("went after this");

      const pageContentData = pages.flatMap(
        (page) =>
          Array.isArray(page.pageContent) && page.pageContent.length > 0
            ? page.pageContent.map((content) => ({
                page_id: page.uuid,
                ...content,
              }))
            : [] // Return an empty array if pageContent is empty or not an array
      );

      console.log("went after flat map");

      // Transform `pageContentData` to stringify `input_fields`
      const transformedPageContentData = pageContentData.map((content) => ({
        ...content,
        input_fields:
          typeof content.input_fields === "string"
            ? content.input_fields // Keep it as-is if already a string
            : JSON.stringify(content.input_fields), // Otherwise, stringify it
      }));

      // Log for debugging
      console.log("this is transforpage");

      console.log(transformedPageContentData);

      // Create the lesson and pages concurrently
      const [lessonResponse, pageResponses, pageContentResponse] =
        await Promise.all([
          // Create or update the lesson
          lessonService.createNewLesson(lessonDetails),
          lessonService.updateLessonPages(pageData),
          lessonService.createLessonContentPages(transformedPageContentData),
        ]);
      console.log("this is page content");

      console.log(pageContentResponse);

      if (lessonResponse.success && pageResponses.success) {
        setSnackbarOpen(true);
        setSnackbarMessage("Lesson Details has been updated");
      }
    } catch (error: any) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    } finally {
      setUpdateLoading(false);
    }
  }
  function handleCloseSnackbar(): void {
    setSnackbarOpen(false);
  }

  async function handleDeletePage(uuid: string, index: number): Promise<void> {
    try {
      setCurrentDeleteLoadingIndex(index); // Show loading for the current delete operation

      if (uuid) {
        // If UUID exists, delete from the database first
        await lessonService.deleteLessonPage(uuid);
        // Remove the page from the local state after successful deletion
        setPages((prevPages) => prevPages.filter((_, i) => i !== index));
        setSnackbarOpen(true);
        setSnackbarMessage("Page deleted successfully");
      } else {
        // If UUID doesn't exist, simply delete from local state
        setPages((prevPages) => prevPages.filter((_, i) => i !== index));
        setSnackbarOpen(true);
        setSnackbarMessage("Page has been deleted");
      }
    } catch (error: any) {
      // Handle errors
      setSnackbarOpen(true);
      setSnackbarMessage(error.message || "Failed to delete page");
    } finally {
      // Reset the loading state
      setCurrentDeleteLoadingIndex(null);
    }
  }

  //// Function for opening the lesson page ---------------------------/
  function openLessonPage(page: LessonPages, index: number) {
    setOpen(true);
    setCurrentOpenedPageIndex(index);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#f9f9f9",
      }}
    >
      {/* Top App Bar */}
      <TopAppBar />

      {/* Main Content */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: 3,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            padding: 3,
            height: "calc(100vh - 120px)", // Dynamically adjust height
          }}
        >
          {/* Lesson Inputs */}
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Lesson Name"
                value={lessonDetails.lessonName}
                onChange={(e) =>
                  setLessonDetails({
                    ...lessonDetails,
                    lessonName: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Lesson Subtext"
                value={lessonDetails.lessonSubtext}
                onChange={(e) =>
                  setLessonDetails({
                    ...lessonDetails,
                    lessonSubtext: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                label="Section"
                value={lessonDetails.section}
                onChange={(e) =>
                  setLessonDetails({
                    ...lessonDetails,
                    section: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                label="Time (minutes)"
                type="number"
                value={lessonDetails.time}
                onChange={(e) =>
                  setLessonDetails({ ...lessonDetails, time: e.target.value })
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{ display: "flex", alignItems: "center", gap: 2 }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={isPublished}
                    onChange={handlePublishToggle}
                    color="primary"
                  />
                }
                label="Publish"
                labelPlacement="end"
              />
              {updateLoading ? (
                <Container>
                  <CircularProgress />
                </Container>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    updateLessons();
                  }}
                  sx={{
                    textTransform: "none",
                    fontWeight: "bold",
                    paddingX: 3,
                  }}
                >
                  Update
                </Button>
              )}
            </Grid>
          </Grid>

          <Divider sx={{ my: 4 }} />

          {/* Pages and Preview Section */}
          <Grid container spacing={3} sx={{ flex: 1 }}>
            {/* Pages Section */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" mb={2}>
                Pages
              </Typography>

              <Box
                sx={{
                  flex: 1,
                  maxHeight: 500, // Fixed height for scrollable section
                  overflowY: "auto",
                  paddingRight: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  border: "1px solid #ccc",
                  borderRadius: 2,
                  padding: 2,
                }}
              >
                {pageLoading ? (
                  <Container
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Container>
                ) : (
                  <>
                    {pages && pages.length > 0 ? (
                      pages.map((page: LessonPages, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 2,
                            border: "1px solid black", // Black border
                            borderRadius: 2,
                            backgroundColor: "white", // White background
                            marginBottom: 1, // Space between items
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            openLessonPage(page, index);
                          }}
                        >
                          {/* Page Name */}
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              textTransform: "none",
                            }}
                          >
                            {page.pageName}
                          </Typography>
                          {currentDeleteLoadingIndex == index ? (
                            <Container
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                              }}
                            >
                              <CircularProgress />
                            </Container>
                          ) : (
                            <IconButton
                              aria-label="delete"
                              onClick={(event) => {
                                event.stopPropagation(); // Prevent click event from propagating to the parent
                                handleDeletePage(page.uuid, index);
                              }}
                              // Disable during deletion
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                          {/* Delete Button */}
                        </Box>
                      ))
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: 200, // Adjust height to your layout
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="body1" color="textSecondary">
                          No pages exist for this lesson
                        </Typography>
                      </Box>
                    )}
                  </>
                )}

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setIsModalOpen(true);
                    setTimeout(() => {
                      ref.current?.focus(); // Trigger focus on the input field
                    }, 0); // Ensure focus happens after modal is rendered
                  }}
                  sx={{
                    padding: 2,
                    textTransform: "none",
                  }}
                >
                  Add Page
                </Button>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {pageLoading ? (
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Container>
              ) : (
                <>
                  <Typography variant="h6" mb={2}>
                    Preview
                  </Typography>
                  <PagePreview pages={pages} />
                </>
              )}
            </Grid>

            {/* Preview Section */}
          </Grid>
        </Paper>
      </Box>

      {/* Modal for Adding Page */}
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="add-page-modal"
        aria-describedby="add-page-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="add-page-modal" variant="h6" mb={2}>
            Add New Page
          </Typography>
          <TextField
            fullWidth
            label="Page Name"
            value={newPageName}
            onChange={(e) => setNewPageName(e.target.value)}
            margin="dense"
            inputRef={ref}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 3,
            }}
          >
            <Button
              variant="outlined"
              onClick={handleModalClose}
              color="secondary"
            >
              Cancel
            </Button>
            {addPagesLoading ? (
              <Container>
                <CircularProgress />
              </Container>
            ) : (
              <Button
                variant="contained"
                onClick={handleAddPage}
                color="primary"
              >
                Add Page
              </Button>
            )}
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
      {/* Modal */}
      {open ? (
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="lg" // Set maximum width
          fullWidth // Ensure full width for the modal
        >
          <DialogTitle>
            Lesson Page
            <IconButton
              onClick={handleClose}
              style={{
                position: "absolute",
                right: 16,
                top: 16,
                background: "transparent",
                border: "none",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            {/* Render the LessonPage component */}
            <LessonPage
              pages={pages}
              currentOpenedPageIndex={currentOpenedPageIndex}
              setPages={setPages}
            />
          </DialogContent>
        </Dialog>
      ) : null}
    </Box>
  );
};

export default LessonDetails;
