import { fetchWrapper } from "../helpers";
import { config } from "../shared/constants/config";

/// Exporting all the function for the ab testing -----------------------/
export const abTestService = {
  fetchExperiments,
  createNewExperiment,
  updateExperiment,
  deleteVariant,
  getSingleExperimentDetails,
  deleteExperiment,
};

//// Funciton for fetching all the experiments ---------------------------/
async function fetchExperiments() {
  return fetchWrapper.get(`${config.apiUrl}/api/get-experiments`);
}

//// Funciton for creating new experiments ---------------------------/
async function createNewExperiment(experimentData: any) {
  return fetchWrapper.post(`${config.apiUrl}/api/create-experiments`, {
    experimentData,
  });
}

//// Funciton for creating new experiments ---------------------------/
async function updateExperiment(experimentData: any) {
  return fetchWrapper.post(`${config.apiUrl}/api/update-experiment`, {
    experimentData,
  });
}

/// Funciton for getting the variants -------------------------------/
async function getSingleExperimentDetails(experiment_id: string) {
  return fetchWrapper.get(
    `${config.apiUrl}/api/get-singleexperiment?experiment_id=${experiment_id}`
  );
}
//// Funciton for creating new experiments ---------------------------/
async function deleteVariant(variant_id: string, experiment_id: string) {
  return fetchWrapper.delete(
    `${config.apiUrl}/api/delete-variant?variant_id=${variant_id}&experiment_id=${experiment_id}`
  );
}

//// Funciton for creating new experiments ---------------------------/
async function deleteExperiment(experiment_id: string) {
  return fetchWrapper.delete(
    `${config.apiUrl}/api/delete-experiment?experiment_id=${experiment_id}`
  );
}
