/** @format */

import * as yup from "yup";

/// Validation schema for the prompt settings--------------------------/
const validationSchemaForPrompt = yup.object({
  nutrition_prompt: yup
    .string()
    .required("A response for the nutrition prompt is required."),
  nutrition_single_prompt: yup
    .string()
    .required("A response for the nutrition single prompt is required."),
  nutrition_multi_prompt: yup
    .string()
    .required("A response for the nutrition multi prompt is required."),
  nutrition_vision_prompt: yup
    .string()
    .required("A response for the nutrition vision prompt is required."),
});

///validation schema for the old version ----------------------------------/
const validationSchemaForOldVersion = yup.object().shape({
  version: yup.string().required("Version is required"),
  releaseNote: yup.string().required("Release Note is required"),
});

///validation schema for the new version ----------------------------------/
const validationSchemaForNewVersion = yup
  .object()
  .shape({
    version: yup.string(), // Optional unless autoVersion is not provided
    autoVersion: yup.string(), // Optional unless version is not provided
    releaseNote: yup.string(), // Optional field
    mandatory: yup
      .string()
      .oneOf(["true", "false"], "Mandatory must be 'true' or 'false'")
      .required("Mandatory is required"), // Mandatory field
  })
  .test(
    "version-or-autoVersion",
    "Either version or autoVersion is required",
    (values) => !!values.version || !!values.autoVersion
  );

const subscriptionSettingsValidationSchema = yup.object().shape({
  subscriptionEnabled: yup.boolean(),
  featureLimits: yup.object().shape({
    logByImage: yup
      .number()
      .min(0, "Must be greater than or equal to 0")
      .required("Required"),
    logByAudio: yup
      .number()
      .min(0, "Must be greater than or equal to 0")
      .required("Required"),
    messagesToFio: yup
      .number()
      .min(0, "Must be greater than or equal to 0")
      .required("Required"),
    singleWorkoutGeneration: yup
      .number()
      .min(0, "Must be greater than or equal to 0")
      .required("Required"),
    workoutPlanGeneration: yup
      .number()
      .min(0, "Must be greater than or equal to 0")
      .required("Required"),
  }),
  initialQuota: yup
    .number()
    .min(0, "Must be greater than or equal to 0")
    .required("Required"),
  trialPeriod: yup
    .number()
    .min(0, "Must be greater than or equal to 0")
    .required("Required"),
});

export {
  validationSchemaForPrompt,
  validationSchemaForOldVersion,
  validationSchemaForNewVersion,
  subscriptionSettingsValidationSchema,
};
