/** @format */

import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Switch,
  Box,
  Snackbar,
  IconButton,
} from "@mui/material";
import TopAppBar from "../modules/TopAppBar";
import { accountService } from "../services";

import NotificationSideBar from "../modules/NotificationSideBar";
import { useLocation } from "react-router-dom";
import { SelectedUserInterface } from "../interface/User";
import { FilterInterface } from "../interface/FilterInterface";
import { notificationUtils } from "../utils/Notification";
import Interaction from "../Components/Campaigns/Interaction";
import Preview from "../Components/Campaigns/Preview";
import { interactionService } from "../services/interaction.service";
import { campaignService } from "../services/campaign.service";

// Main Function of the component
const CampaignDetails = () => {
  const location = useLocation();

  const { allUsers, totalCount, fetchedInteractions } = location.state || {};

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  const [userArray, setUserArray] = useState<any[]>();

  const [userLoading, setUserLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [fixedUsers, setFixedUsers] = useState<SelectedUserInterface[]>([]);
  const [totalUsers, setTotalUsers] = useState<SelectedUserInterface[]>([]);
  const [totalCheckItemNumber, setTotalCheckItemNumber] = useState(0);
  const [totalNumberOfUsers, setTotalNumberOfUsers] = useState<number>(0);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [campaignSaveLoading, setCampaignSaveLoading] = useState(false);
  const [firstTimeLoad, setFirstTimeLoad] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const listInnerRef = useRef<HTMLUListElement>(null);
  const [numberofCalls, setNumberOfCalls] = useState(2);
  const [selectAll, setSelectAll] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [hasMoreUsers, setHasMoreUsers] = useState(true);
  const [fixedUserNumber, setFixedUserNumber] = useState(0);
  const [countries, setCountries] = useState<any[]>([]);
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
  // Extract campaign ID and name from the query parameters
  const query = new URLSearchParams(location.search);
  const campaignId = query.get("campaignId") || ""; // Provide a fallback empty string
  const campaignName = query.get("campaignName") || "Unknown Campaign"; // Fallback value for campaignName

  const start_dt = query.get("start_dt") || null;
  const end_dt = query.get("end_dt") || null;
  const status = query.get("status") || null;

  const [interactions, setInteractions] = useState<any>([]);
  useEffect(() => {
    if (start_dt && end_dt) {
      setDateRange({
        startDate: new Date(start_dt),
        endDate: new Date(end_dt),
      });
    }

    if (status === "inactive" || status === "draft") {
      setIsToggled(false);
    } else {
      setIsToggled(true);
    }
    if (fetchedInteractions) {
      const parsedInteractions = fetchedInteractions.map((interaction: any) => {
        // Parse the input_fields from JSON string to object
        let parsedInputFields = {};
        try {
          parsedInputFields = JSON.parse(interaction.input_fields);
        } catch (error) {
          console.error("Failed to parse input_fields:", error);
        }

        return {
          ...interaction,
          input_fields: parsedInputFields, // Replace the string with the parsed object
        };
      });

      setInteractions(parsedInteractions);
    }
  }, []);

  const [dateRange, setDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: null,
    endDate: null,
  });
  const handleAddInteraction = (newInteraction: any) => {
    setInteractions([...interactions, newInteraction]);
  };

  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };

  async function fetchSelectedAudiences() {
    // Fetch the array of userIds
    const response = await campaignService.getAllAudiences(campaignId);

    if (response.success) {
      // Store the mapped data in the state
      setSelectedUserIds(response.data);
      setTotalCheckItemNumber(response.data.length);
    }
  }

  useEffect(() => {
    fetchSelectedAudiences();
  }, []);

  const handleSaveClick = async () => {
    setCampaignSaveLoading(true);
    if (selectedUserIds.length > 0) {
      try {
        // Prepare the interactions data
        const formattedInteractions = interactions.map(
          (interaction: any, index: any) => {
            let { type } = interaction;
            const sequence = index + 1; // Assign sequence number starting from 1

            return {
              type: type.toLowerCase(), // Convert type to lowercase
              sequence,
              campaign_id: campaignId,
              input_fields: JSON.stringify(interaction.input_fields),
            };
          }
        );

        // Prepare the campaign data
        const campaignData = {
          campaign_id: campaignId,
          name: campaignName, // Or any appropriate name for the campaign
          status: isToggled ? "active" : "inactive",
          startDate: dateRange.startDate, // Assuming these are the dates you want to use
          endDate: dateRange.endDate,
        };
        // Prepare the user-campaign association data
        const audiencesData = {
          campaign_id: campaignId,
          audiences: selectedUserIds.map(
            (userDetails: any) => userDetails.userId
          ),
        };

        // Execute both API calls in parallel
        const [
          interactionResponse,
          campaignResponse,
          addAudienceResponse,
          addEventToCampaignResponse,
        ] = await Promise.all([
          interactionService.createInteraction(formattedInteractions),
          campaignService.createNewCampaign(campaignData),
          campaignService.addAudiences(audiencesData),
          campaignService.addEventToCampaign(campaignId, selectedEvents[0]),
        ]);

        // Handle responses
        if (
          interactionResponse.success &&
          campaignResponse.success &&
          addAudienceResponse.success
        ) {
          setSnackbarMessage("Settings saved and campaign created!");
          setSnackbarOpen(true);

          // Optionally, update the campaigns list or any other state as needed
          //setCampaigns([...campaigns, campaignResponse.data]);
        } else {
          console.error(
            "Failed to create interactions or campaign:",
            interactionResponse.message,
            campaignResponse.message
          );
        }
      } catch (error) {
        console.error("Error creating interactions or campaign:", error);
      } finally {
        setCampaignSaveLoading(false);
      }
    } else {
      setSnackbarMessage("Select the audience first");
      setSnackbarOpen(true);
      setCampaignSaveLoading(false);
    }
  };

  const fetchUsers = async () => {
    setUserLoading(true);
    try {
      const user = accountService.userValue;
      let numberofCalls = 1;
      const params = {
        numberofCalls: numberofCalls,
        userGroupNumber: 20,
        trainerCode: user.role === "admin" ? undefined : user.uniqueTrainerCode,
        activity: {
          date: null,
          activityType: "",
        },
        dateRange: {
          startDate: null,
          endDate: null,
        },
        signUpFilter: null,
      };

      const res = await accountService.getAllUsers(params);
      let { users, userCount } = res;
      const sortedUsers = users.sort((a: any, b: any) => {
        if (a.messageSeen === b.messageSeen) {
          return 0;
        }
        return a.messageSeen ? -1 : 1;
      });
      setFirstTimeLoad(true);

      setTotalUsers(sortedUsers);
      setFixedUsers(sortedUsers);
      setUserLoading(false);
      setFixedUserNumber(userCount);
      setTotalNumberOfUsers(userCount);
      const loggedUserr = await accountService.userValue;

      /// Service function call for getting the country names-/
      const countryApiResponse = await accountService.getCountries(
        loggedUserr.id
      );

      if (countryApiResponse.success === true) {
        setCountries([...countryApiResponse.countryArray]);
      } else {
        setCountries([]);
      }
    } catch (error) {
      setUserLoading(false);
    }
  };

  useEffect(() => {
    if (allUsers !== undefined && allUsers.length > 0) {
      setTotalUsers(allUsers);
      setFixedUsers(allUsers);
      setFixedUserNumber(totalCount);
      setTotalNumberOfUsers(totalCount);
    } else {
      fetchUsers();
    }
  }, []);

  const fetchMoreUsers = async (
    numberofCalls: any,
    filters: FilterInterface,
    hasMoreUsers: boolean
  ) => {
    if (!hasMoreUsers) {
      return;
    }
    setIsLoadingMore(true);

    try {
      const user = accountService.userValue;

      const params = {
        numberofCalls: numberofCalls + 1,
        userGroupNumber: 20,
        deviceType: filters.deviceType, // Add device type if active
        onboarding: filters.onboardingFilter, // Add onboarding status if active
        subscription: filters.subscriptionFilter, // Add subscription status if active
        trainerCode: user.role === "admin" ? undefined : user.uniqueTrainerCode, // Add trainer code if user is not admin
        countries: filters.checkBoxCountries, // Include selected countries in the request
        notification: filters.notificationFilter,
        maxVersion: filters.maxVersion,
        minVersion: filters.minVersion,
        genderType: filters.genderType,
        goalType: filters.goalType,
        activeType: filters.activeType,
        activity: filters.activity,
        dateRange: filters.dateRange,
        contactNumber: filters.contactNumberFilter,
        notes: filters.notesFilter,
        uncheckedCountries: filters.uncheckCountries,
        messageSeen: filters.messageSeenFilter,
        countryCode: filters.countryCode,
        csvFile: filters.csvFileFilter,
        distinctIds: filters.distinctIds,
        openSupportMessage: filters.openSupportMessage,
        minimumAge: filters.minimumAge,
        maximumAge: filters.maximumAge,
        maximumBmi: filters.maximumBmi,
        minimumBmi: filters.minimumBmi,
        freePremium: filters.freePremiumFilter,
        freeTrial: filters.freeTrialFilter,
        paidFilter: filters.paidFilter,
        signUpFilter: filters.signUpFilter,
        subscriptionCanceledFilter: filters.subscriptionCanceledFilter,
      };

      const additionalUsers = await accountService.getAllUsers(params);

      let { users, userCount } = additionalUsers;
      if (users.length > 0) {
        setTotalUsers((prevUsers: any) => [...prevUsers, ...users]);
        setNumberOfCalls((prevNumber) => prevNumber + 1);
        setTotalNumberOfUsers(userCount);
        if (selectAll) {
          const newSelectedUsers = users.map((user: any) => ({
            userId: user._id,
            fcmToken: user.fcmToken,
          }));
          setSelectedUserIds((prevSelectedIds) => [
            ...prevSelectedIds,
            ...newSelectedUsers,
          ]);
        }
      } else {
        setHasMoreUsers(false);
        setNumberOfCalls((prevNumber) => prevNumber + 1);
      }
    } catch (error) {
      console.error("Failed to load more users:", error);
    }
    setIsLoadingMore(false);
  };

  function handleCloseSnackbar(): void {
    setSnackbarOpen(false);
  }

  return (
    <Grid container direction="column" sx={{ minHeight: "100vh" }}>
      <Grid item>
        <TopAppBar />
      </Grid>

      <Grid item container sx={{ p: 2, flex: 1 }}>
        {/* Left Sidebar */}
        <Grid item xs={12} md={3}>
          <NotificationSideBar
            selectedUserIds={selectedUserIds}
            setSelectedUserIds={setSelectedUserIds}
            settotalUsers={setTotalUsers}
            totalUsers={totalUsers}
            fixedUsers={fixedUsers}
            userLoading={userLoading}
            totalNumberOfUsers={totalNumberOfUsers}
            handleScroll={fetchMoreUsers}
            listInnerRef={listInnerRef}
            isLoadingMore={isLoadingMore}
            setUserLoading={setUserLoading}
            fetchUsers={fetchUsers}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            numberofCalls={numberofCalls}
            setNumberOfCalls={setNumberOfCalls}
            setTotalNumberOfUsers={setTotalNumberOfUsers}
            totalCheckItemNumber={totalCheckItemNumber}
            setTotalCheckItemNumber={setTotalCheckItemNumber}
            selectType="id"
            userArray={userArray}
            setUserArray={setUserArray}
            fixedUserNumber={fixedUserNumber}
            setFixedUserNumber={setFixedUserNumber}
            hasMoreUsers={hasMoreUsers}
            setHasMoreUsers={setHasMoreUsers}
            firstTimeLoad={firstTimeLoad}
            setFirstTimeLoad={setFirstTimeLoad}
            countries={countries}
          />
        </Grid>

        {/* Center Section: Events */}
        <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
          <Interaction
            campaignId={campaignId}
            campaignName={campaignName}
            isToggled={isToggled}
            handleToggleChange={handleToggleChange}
            handleSaveClick={handleSaveClick}
            addInteraction={handleAddInteraction} // Pass the handler function as a prop
            interactions={interactions} // Pass the current interactions state
            dateRange={dateRange}
            setDateRange={setDateRange}
            campaignSaveLoading={campaignSaveLoading}
            setInteractions={setInteractions}
            selectedEvents={selectedEvents}
            setSelectedEvents={setSelectedEvents}
          />
        </Grid>

        {/* Right Section: Preview */}
        <Preview interactions={interactions} />
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </Grid>
  );
};

export default CampaignDetails;
