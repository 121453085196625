/** @format */

import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { memo, useEffect, useState } from "react";

import { accountService } from "../../services";
import { validationSchemaForNewVersion } from "../../utils/AppSettings";
interface NewVersion {
  releaseNote: any;
  setReleaseNote: any;
}
function NewVersion({ releaseNote, setReleaseNote }: NewVersion) {
  const [androidVersionsNew, setAndroidVersionsNew] = useState<any>([
    { version: "", mandatory: false },
  ]);

  const [iosVersionsNew, setIosVersionsNew] = useState<any>([
    { version: "", mandatory: false },
  ]);
  const [loading, setLoading] = useState(true);

  const [androidInputOpenNew, setAndroidInputOpenNew] = useState(false);

  const [iosInputOpenNew, setIosInputOpenNew] = useState(false);

  const [submitLoadingNew, setSubmitLoadingNew] = useState(false);

  const toggleAndroidInputNew = () => {
    setAndroidInputOpenNew(!androidInputOpenNew);
    if (iosInputOpenNew) {
      setIosInputOpenNew(false);
    }
  };

  const toggleIosInputNew = () => {
    setIosInputOpenNew(!iosInputOpenNew);
    if (androidInputOpenNew) {
      setAndroidInputOpenNew(false);
    }
  };
  const fetchData = async () => {
    try {
      const cachebusterAndroid = Math.floor(Math.random() * 100) + 1;
      const cachebusterIos = Math.floor(Math.random() * 200) + 1;
      const storageNameResponse = await accountService.getStorageName();

      const baseUrl = `https://${storageNameResponse.storageName}.blob.core.windows.net/reshape-public`;
      const androidVersionV2Url = `${baseUrl}/versioning-android-v2.json?cachebuster=${cachebusterAndroid}`;
      const androidReleaseNoteUrl = `${baseUrl}/release-notes-android.json?cachebuster=${cachebusterAndroid}`;
      const iosVersionV2Url = `${baseUrl}/versioning-ios-v2.json?cachebuster=${cachebusterIos}`;
      const iosReleaseNoteUrl = `${baseUrl}/release-notes-ios.json?cachebuster=${cachebusterIos}`;

      // Fetch all URLs in parallel
      const [
        newVersionAndroidResponse,
        androidReleaseNoteResponse,
        newVersionIosResponse,
        iosReleaseNoteResponse,
      ] = await Promise.all([
        fetch(androidVersionV2Url),
        fetch(androidReleaseNoteUrl),
        fetch(iosVersionV2Url),
        fetch(iosReleaseNoteUrl),
      ]);

      // Parse JSON responses in parallel
      const [
        newVersionAndroidData,
        androidReleaseNote,
        newVersionIosData,
        iosReleaseNote,
      ] = await Promise.all([
        newVersionAndroidResponse.json(),
        androidReleaseNoteResponse.json(),
        newVersionIosResponse.json(),
        iosReleaseNoteResponse.json(),
      ]);

      // Update state with the fetched data
      setReleaseNote({ android: androidReleaseNote, ios: iosReleaseNote });
      setAndroidVersionsNew(newVersionAndroidData);
      setIosVersionsNew(newVersionIosData);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmitForNewVersion = async (
    values: any,
    actions: any,
    type: any
  ) => {
    //setSubmitLoadingNew(true);
    if (values.autoVersion === "" && values.version === "") {
      alert("Kindly write down one of the version");
      return;
    }
    console.log("done");

    try {
      const { version, releaseNote, mandatory, autoVersion } = values;
      const newVersion = version.trim();
      const newAutoVersion = autoVersion.trim();
      let newObj = {
        version: newVersion,
        mandatory: mandatory,
        autoVersion: newAutoVersion,
      };
      const releaseNotes = releaseNote.trim();
      let newValue = true;

      // Ensure both version and autoVersion are checked
      if (newVersion || newAutoVersion) {
        let updatedVersions: any[] = [];

        if (type === "android") {
          setReleaseNote((value: any) => ({
            ...value,
            android: releaseNotes,
          }));
          console.log("went till here");
          // Loop through android versions to check for new version or autoVersion
          for (let i = 0; i < androidVersionsNew.length; i++) {
            if (
              androidVersionsNew[i].version === newVersion ||
              androidVersionsNew[i].autoVersion === newAutoVersion
            ) {
              newValue = false;
            } else {
              updatedVersions = [newObj, ...androidVersionsNew];
              setAndroidVersionsNew(updatedVersions);
            }
          }
        } else if (type === "ios") {
          setReleaseNote((value: any) => ({
            ...value,
            ios: releaseNotes,
          }));

          // Loop through ios versions to check for new version or autoVersion
          for (let i = 0; i < iosVersionsNew.length; i++) {
            if (
              iosVersionsNew[i].version === newVersion ||
              iosVersionsNew[i].autoVersion === newAutoVersion
            ) {
              newValue = false;
            } else {
              updatedVersions = [newObj, ...iosVersionsNew];
              setIosVersionsNew(updatedVersions);
            }
          }
        }

        // Proceed with update if new version or autoVersion is valid
        if (newValue === true) {
          const updateResponse = await accountService.updateVersionNew(
            type,
            newVersion,
            newAutoVersion,
            releaseNotes,
            mandatory
          );

          if (updateResponse.success === true) {
            actions.resetForm();
            if (type === "android") {
              toggleAndroidInputNew(); // Close the input section
            } else if (type === "ios") {
              toggleIosInputNew(); // Close the input section
            }
            alert(updateResponse.message);
          } else {
            actions.resetForm();
            if (type === "android") {
              toggleAndroidInputNew(); // Close the input section
            } else if (type === "ios") {
              toggleIosInputNew(); // Close the input section
            }
            alert(updateResponse.message);
          }
        } else {
          alert("Kindly write a new version or auto version");
        }
      }
      setSubmitLoadingNew(false);
    } catch (error) {
      console.error("Error in version submission:", error);
      setSubmitLoadingNew(false);
      alert("An error occurred while submitting. Please try again.");
    }
  };
  return (
    <>
      <Box p={4} style={{ backgroundColor: "#f0f0f0" }}>
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              marginTop: 10,
              marginBottom: 10,
              fontSize: 20,
              fontWeight: "700",
            }}
          >
            New Versioning
          </Typography>
        </Container>
        {loading ? (
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Container>
        ) : (
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Box
                border={1}
                borderRadius={5}
                p={3}
                style={{ backgroundColor: "#fff", position: "relative" }}
              >
                <Button
                  onClick={toggleAndroidInputNew}
                  variant="contained"
                  color="primary"
                  style={{ position: "absolute", top: 10, right: 10 }}
                >
                  {androidInputOpenNew ? "Close" : "Add Version"}
                </Button>
                <Typography variant="h6">Android</Typography>
                <>
                  {androidVersionsNew[0].version && (
                    <Typography variant="body1" style={{ marginTop: 10 }}>
                      Version: {androidVersionsNew[0].version}
                    </Typography>
                  )}
                  {androidVersionsNew[0].autoVersion && (
                    <Typography variant="body1" style={{ marginTop: 10 }}>
                      Auto Version: {androidVersionsNew[0].autoVersion}
                    </Typography>
                  )}
                </>

                <Typography variant="body1" style={{ marginTop: 10 }}>
                  Mandatory:{" "}
                  {androidVersionsNew[0].mandatory ? "true" : "false"}
                </Typography>
                <Box
                  display="flex"
                  alignItems="flex-start" // Adjust to align items at the start
                  style={{ marginTop: 10 }}
                >
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{ whiteSpace: "nowrap", marginRight: 1 }}
                  >
                    Release Note:
                  </Typography>
                  <Box
                    sx={{
                      overflow: "auto", // Enables scrolling
                      height: 50,
                      marginLeft: 1,
                      width: "100%", // Ensure it takes up the remaining space
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ textAlign: "justify" }}
                    >
                      {releaseNote.android}
                    </Typography>
                  </Box>
                </Box>
                {androidInputOpenNew && (
                  <Formik
                    initialValues={{
                      version: "",
                      releaseNote: "",
                      autoVersion: "",
                      mandatory: false,
                    }}
                    validationSchema={validationSchemaForNewVersion}
                    onSubmit={(values, actions) =>
                      handleSubmitForNewVersion(values, actions, "android")
                    }
                  >
                    {({ handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <Field name="version">
                          {({ field, form }: any) => (
                            <TextField
                              {...field}
                              label="Version"
                              multiline
                              rows={1}
                              variant="outlined"
                              fullWidth
                              style={{ marginTop: "25px" }}
                              error={
                                form.touched.version && !!form.errors.version
                              }
                              helperText={
                                form.touched.version && form.errors.version
                              }
                            />
                          )}
                        </Field>

                        <Field name="autoVersion">
                          {({ field, form }: any) => (
                            <TextField
                              {...field}
                              label="Auto Version"
                              multiline
                              rows={1}
                              variant="outlined"
                              fullWidth
                              style={{ marginTop: "25px" }}
                              error={
                                form.touched.autoVersion &&
                                !!form.errors.autoVersion
                              }
                              helperText={
                                form.touched.autoVersion &&
                                form.errors.autoVersion
                              }
                            />
                          )}
                        </Field>

                        <Field name="releaseNote">
                          {({ field, form }: any) => (
                            <TextField
                              {...field}
                              label="Release Note"
                              variant="outlined"
                              multiline
                              fullWidth
                              style={{ marginTop: 20 }}
                              rows={4}
                              error={
                                form.touched.releaseNote &&
                                !!form.errors.releaseNote
                              }
                              helperText={
                                form.touched.releaseNote &&
                                form.errors.releaseNote
                              }
                            />
                          )}
                        </Field>

                        <Field name="mandatory">
                          {({ field, form }: any) => (
                            <FormControl
                              variant="outlined"
                              fullWidth
                              style={{ marginTop: 20 }}
                              error={
                                form.touched.mandatory &&
                                !!form.errors.mandatory
                              }
                            >
                              <InputLabel id="mandatory-label">
                                Mandatory
                              </InputLabel>
                              <Select
                                labelId="mandatory-label"
                                {...field}
                                label="Mandatory"
                              >
                                <MenuItem value={"true"}>True</MenuItem>
                                <MenuItem value={"false"}>False</MenuItem>
                              </Select>
                              {form.touched.mandatory &&
                                form.errors.mandatory && (
                                  <FormHelperText>
                                    {form.errors.mandatory}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          )}
                        </Field>

                        {submitLoadingNew ? (
                          <Container>
                            <CircularProgress style={{ marginTop: "10px" }} />
                          </Container>
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "20px" }}
                          >
                            Submit
                          </Button>
                        )}
                      </Form>
                    )}
                  </Formik>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                border={1}
                borderRadius={5}
                p={3}
                style={{ backgroundColor: "#fff", position: "relative" }}
              >
                <Button
                  onClick={toggleIosInputNew}
                  variant="contained"
                  color="primary"
                  style={{ position: "absolute", top: 10, right: 10 }}
                >
                  {iosInputOpenNew ? "Close" : "Add Version"}
                </Button>
                <Typography variant="h6">IOS</Typography>
                <>
                  {iosVersionsNew[0].version && (
                    <Typography variant="body1" style={{ marginTop: 10 }}>
                      Version: {iosVersionsNew[0].version}
                    </Typography>
                  )}
                  {iosVersionsNew[0].autoVersion && (
                    <Typography variant="body1" style={{ marginTop: 10 }}>
                      Auto Version: {iosVersionsNew[0].autoVersion}
                    </Typography>
                  )}
                </>
                <Typography variant="body1" style={{ marginTop: 10 }}>
                  Mandatory: {iosVersionsNew[0].mandatory ? "true" : "false"}
                </Typography>
                <Box
                  display="flex"
                  alignItems="flex-start" // Adjust to align items at the start
                  style={{ marginTop: 10 }}
                >
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{ whiteSpace: "nowrap", marginRight: 1 }}
                  >
                    Release Note:
                  </Typography>
                  <Box
                    sx={{
                      overflow: "auto", // Enables scrolling
                      height: 50,
                      marginLeft: 1,
                      width: "100%", // Ensure it takes up the remaining space
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ textAlign: "justify" }}
                    >
                      {releaseNote.ios}
                    </Typography>
                  </Box>
                </Box>
                {iosInputOpenNew && (
                  <Formik
                    initialValues={{
                      version: "",
                      releaseNote: "",
                      autoVersion: "",
                      mandatory: false,
                    }}
                    validationSchema={validationSchemaForNewVersion}
                    onSubmit={(values, actions) =>
                      handleSubmitForNewVersion(values, actions, "ios")
                    }
                  >
                    {({ handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <Field name="version">
                          {({ field, meta }: any) => (
                            <TextField
                              {...field}
                              label="Version"
                              variant="outlined"
                              fullWidth
                              style={{ marginTop: "25px" }}
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        </Field>

                        <Field name="autoVersion">
                          {({ field, form }: any) => (
                            <TextField
                              {...field}
                              label="Auto Version"
                              multiline
                              rows={1}
                              variant="outlined"
                              fullWidth
                              style={{ marginTop: "25px" }}
                              error={
                                form.touched.autoVersion &&
                                !!form.errors.autoVersion
                              }
                              helperText={
                                form.touched.autoVersion &&
                                form.errors.autoVersion
                              }
                            />
                          )}
                        </Field>
                        <Field name="releaseNote">
                          {({ field, meta }: any) => (
                            <TextField
                              {...field}
                              label="Release Note"
                              rows={4}
                              variant="outlined"
                              fullWidth
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                              multiline={true}
                              style={{ marginTop: 20 }}
                            />
                          )}
                        </Field>
                        <Field name="mandatory">
                          {({ field, form: { touched, errors } }: any) => (
                            <FormControl
                              variant="outlined"
                              fullWidth
                              style={{ marginTop: 20 }}
                            >
                              <InputLabel id="mandatory-label">
                                Mandatory
                              </InputLabel>
                              <Select
                                labelId="mandatory-label"
                                {...field}
                                label="Mandatory"
                                error={touched.mandatory && !!errors.mandatory}
                              >
                                <MenuItem value={"true"}>True</MenuItem>
                                <MenuItem value={"false"}>False</MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </Field>

                        {submitLoadingNew ? (
                          <Container>
                            <CircularProgress style={{ marginTop: "10px" }} />
                          </Container>
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "20px" }}
                          >
                            Submit
                          </Button>
                        )}
                      </Form>
                    )}
                  </Formik>
                )}
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}

export default memo(NewVersion);
