import { config } from "../shared/constants/config";
import { fetchWrapper } from "../helpers";
import { LessonPages } from "../interface/LessonInterface";
const baseUrl = `${config.apiUrl}/api`;

export const lessonService = {
  getAllLessons,
  createNewLesson,
  deleteLesson,
  createLessonPages,
  getLessonPages,
  createLessonContentPages,
  deleteLessonPage,
  updateLessonPages,
};

//// Service funciton for fetching the lessons -----------------------------/
async function getAllLessons(): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.get(`${baseUrl}/get-lesson`);
}

/// Service function for creating new lesson -----------------------------/
async function createNewLesson(lessonData: any): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  console.log("this is lesson data");

  console.log(lessonData);

  return fetchWrapper.post(`${baseUrl}/create-lesson`, { ...lessonData });
}
/// Service function for deleting  the lesson -----------------------------/
async function deleteLesson(uuid: any): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.delete(`${baseUrl}/delete-lesson?uuid=${uuid}`);
}

//// Service function for creating new pages in a lesson --------------------/
async function createLessonPages(LessonPages: any) {
  console.log("this is lesson page");

  console.log(LessonPages);

  return fetchWrapper.post(`${baseUrl}/create-lesson-pages`, {
    LessonPages,
  });
}

//// Service function for updating lessons --------------------/
async function updateLessonPages(LessonPages: any) {
  return fetchWrapper.put(`${baseUrl}/update-lessons`, {
    LessonPages,
  });
}
//// Service function for creating new pages in a lesson --------------------/
async function createLessonContentPages(LessonPagesContent: any[]) {
  return fetchWrapper.post(`${baseUrl}/create-lessonpagecontent`, {
    LessonPagesContent,
  });
}
//// Service function for getting Pages for an lesson --------------------/
async function getLessonPages(lesson_id: string) {
  return fetchWrapper.get(`${baseUrl}/get-lesson-pages?lesson_id=${lesson_id}`);
}
//// Service function for getting Pages for an lesson --------------------/
async function deleteLessonPage(page_id: string) {
  return fetchWrapper.delete(
    `${baseUrl}/delete-lesson-page?page_id=${page_id}`
  );
}
