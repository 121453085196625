/** @format */

import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  Snackbar,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  PromptsInterface,
  SinglePromptInterface,
} from "../../../interface/RecipeInterface";
import { recipeService } from "../../../services/recipe.service";

function SinglePromptSection({
  setSingleRecipe,
  singleRecipe,
  setSingleRecipeLoading,
  singleRecipeLoading,
  setSingleRecipeImageLoading,
  prompts,
  setPrompts,
}: SinglePromptInterface) {
  const [mode, setMode] = useState("AI");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [useDalle, setUseDalle] = useState(true);
  const [singleChatGptPrompt, setSingleChatGptPrompt] = useState("");
  const [dalleImagePrompt, setDalleImagePrompt] = useState("");

  const [initialValues, setInitialValues] = useState({
    dalleInput: "",
    chatGptInput: "",
    recipeInput: "",
  });

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleSubmit = async (values: any) => {
    setSingleRecipeLoading(true);
    setSingleRecipeImageLoading(true);
    setSingleRecipe(null);
    try {
      // Retrieve the entire manually typed input including the output structure from values.chatGpt4
      let manualPrompt = values.chatGptInput.replace(/\n/g, "\\n");
      setPrompts((prev: PromptsInterface) => ({
        ...prev,
        gpt: values.chatGptInput,
        dalle: values.dalleInput,
        recipeDetails: values.recipeInput,
      }));
      // Add the logging information at the beginning of the prompt
      let fullPrompt = `- Input User query is provided to you as "recipe details"\n${manualPrompt}`;
      let dalleImagePrompt = `${values.dalleInput} Recipe Details is ${values.recipeInput}`;
      // Store in local storage
      localStorage.setItem("singleChatGptPrompt", fullPrompt);
      localStorage.setItem("dalleImagePrompt", values.dalleInput);
      // Call the ChatGPT API
      const chatGptResponsePromise = recipeService.getSingleRecipesFromAi(
        fullPrompt,
        values.recipeInput
      );

      let promptValue = [];
      promptValue.push(dalleImagePrompt);

      // Call the DALL-E API if useDalle is true
      const dalleResponsePromise = useDalle
        ? recipeService.getImageForDalle(promptValue, "single")
        : Promise.resolve(null);

      chatGptResponsePromise
        .then((chatGptResponse) => {
          if (chatGptResponse.success) {
            const { recipeDetails } = chatGptResponse;

            // Calculate totals if ingredients are not empty
            if (
              recipeDetails.ingredients &&
              recipeDetails.ingredients.length > 0
            ) {
              const totalMacros = recipeDetails.ingredients.reduce(
                (totals: any, ingredient: any) => {
                  return {
                    calories: totals.calories + (ingredient.calories || 0),
                    protein: totals.protein + (ingredient.protein || 0),
                    carbs: totals.carbs + (ingredient.carbs || 0),
                    fats: totals.fats + (ingredient.fats || 0),
                    fiber: totals.fiber + (ingredient.fiber || 0),
                    added_sugar:
                      totals.added_sugar + (ingredient.added_sugar || 0),
                  };
                },
                {
                  calories: 0,
                  protein: 0,
                  carbs: 0,
                  fats: 0,
                  fiber: 0,
                  added_sugar: 0,
                } // Initialize totals
              );

              // Update recipe details with calculated totals
              recipeDetails.calories =
                totalMacros?.calories !== undefined
                  ? totalMacros.calories === 0
                    ? 0
                    : parseFloat(totalMacros.calories.toFixed(1))
                  : null;

              recipeDetails.protein =
                totalMacros?.protein !== undefined
                  ? totalMacros.protein === 0
                    ? 0
                    : parseFloat(totalMacros.protein.toFixed(1))
                  : null;

              recipeDetails.carbs =
                totalMacros?.carbs !== undefined
                  ? totalMacros.carbs === 0
                    ? 0
                    : parseFloat(totalMacros.carbs.toFixed(1))
                  : null;

              recipeDetails.fats =
                totalMacros?.fats !== undefined
                  ? totalMacros.fats === 0
                    ? 0
                    : parseFloat(totalMacros.fats.toFixed(1))
                  : null;

              recipeDetails.fiber =
                totalMacros?.fiber !== undefined
                  ? totalMacros.fiber === 0
                    ? 0
                    : parseFloat(totalMacros.fiber.toFixed(1))
                  : null;

              recipeDetails.added_sugar =
                totalMacros?.added_sugar !== undefined
                  ? totalMacros.added_sugar === 0
                    ? 0
                    : parseFloat(totalMacros.added_sugar.toFixed(1))
                  : null;
            }
            console.log("this is recipe details after formatting");

            // Update the state
            setSingleRecipe((prevState: any) => ({
              ...prevState,
              ...recipeDetails,
            }));

            setSnackbarOpen(true);
            setSnackbarMessage("Recipe details fetched successfully!");
          } else {
            setSnackbarOpen(true);
            setSnackbarMessage(chatGptResponse.message);
          }
        })
        .catch((error) => {
          console.error("Error fetching recipe details:", error);
          setSnackbarOpen(true);
          setSnackbarMessage("Error fetching recipe details.");
        })
        .finally(() => {
          setSingleRecipeLoading(false);
        });

      // Handle the DALL-E response
      dalleResponsePromise
        .then((dalleResponse) => {
          if (dalleResponse && dalleResponse.success) {
            setSingleRecipe((prevState: any) => ({
              ...prevState,
              image: dalleResponse.recipeImage[0], // Assuming dalleResponse has an 'image' property
            }));

            setSnackbarOpen(true);
            setSnackbarMessage("Recipe image fetched successfully!");
          }
        })
        .catch((error) => {
          console.error("Error fetching recipe image:", error);
          setSnackbarOpen(true);
          setSnackbarMessage("Error fetching recipe image.");
        })
        .finally(() => {
          setSingleRecipeImageLoading(false);
        });
    } catch (error) {
      console.log(error);

      setSingleRecipeLoading(false);
    }
  };
  useEffect(() => {
    // Fetch values from local storage
    const singleChatGptPrompt = localStorage.getItem("singleChatGptPrompt");
    const storedDalleImagePrompt = localStorage.getItem("dalleImagePrompt");

    // Update states if values are found in local storage
    if (singleChatGptPrompt) {
      setSingleChatGptPrompt(singleChatGptPrompt);
    }

    if (storedDalleImagePrompt) {
      setDalleImagePrompt(storedDalleImagePrompt);
    }
    // Set initial values for Formik form once fullPrompt and dalleImagePrompt are updated
    setInitialValues({
      dalleInput: storedDalleImagePrompt || "",
      chatGptInput: singleChatGptPrompt || "",
      recipeInput: "", // Keep empty if not specified
    });
  }, []); // Empty dependency array ensures this runs once after the initial render

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",

        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "95%",
          backgroundColor: "#ffffff",

          borderRadius: 2,
          width: "95%",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ToggleButtonGroup
            color="primary"
            value={mode}
            exclusive
            onChange={(event, newMode) => newMode && setMode(newMode)}
            sx={{ mb: 2, width: "100%", justifyContent: "center" }}
          >
            <ToggleButton value="AI">AI</ToggleButton>
            <ToggleButton value="Manual">Manual</ToggleButton>
          </ToggleButtonGroup>
          {/* Switch component */}
          <FormControlLabel
            control={
              <Switch
                checked={useDalle}
                onChange={() => setUseDalle(!useDalle)}
                color="primary"
              />
            }
            label={useDalle ? "Dalle" : "Dalle"}
            labelPlacement="start"
            sx={{
              "& .MuiFormControlLabel-label": {
                color: useDalle ? "primary.main" : "text.primary",
                fontWeight: "bold",
              },
              marginRight: 2,
            }}
          />
        </Box>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {mode === "AI" ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      width: "95%",
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 1, textAlign: "left" }}>
                      DALL-E Prompt
                    </Typography>

                    <Field
                      name="dalleInput"
                      as={TextField}
                      multiline
                      rows={2}
                      fullWidth
                      variant="outlined"
                      sx={{ mb: 2 }}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        setFieldValue("dalleInput", value); // Update Formik state
                        setPrompts((prev: PromptsInterface) => ({
                          ...prev,
                          dalle: value,
                        }));
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      width: "95%",
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      ChatGPT Prompt
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      width: "95%",
                    }}
                  >
                    <Field
                      name="chatGptInput"
                      as={TextField}
                      multiline
                      rows={12}
                      fullWidth
                      variant="outlined"
                      sx={{ mb: 2 }}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        setFieldValue("chatGptInput", value); // Update Formik state
                        setPrompts((prev: PromptsInterface) => ({
                          ...prev,
                          gpt: value,
                        }));
                      }}
                    />
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      Recipe Details
                    </Typography>

                    <Field
                      name="recipeInput"
                      as={TextField}
                      multiline
                      rows={2}
                      fullWidth
                      variant="outlined"
                      sx={{ mb: 2 }}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        setFieldValue("recipeInput", value); // Update Formik state

                        setPrompts((prev: PromptsInterface) => ({
                          ...prev,
                          recipeDetails: value,
                        }));
                      }}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    Manual Input
                  </Typography>
                  <Field
                    name="manualInput"
                    as={TextField}
                    multiline
                    rows={8}
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />
                </>
              )}
              <Button
                type="submit"
                disabled={isSubmitting}
                fullWidth
                variant="contained"
                sx={{ mt: 2, width: "95%" }}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: "#1976d2", // Use primary color from the theme
              color: "white", // Set text color to white
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default SinglePromptSection;
