import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  CircularProgress,
  Container,
  Snackbar,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TopAppBar from "../modules/TopAppBar";
import { useEffect, useState } from "react";
import { LessonInterface } from "../interface/LessonInterface";
import { lessonService } from "../services/lesson.service";
import { useNavigate } from "react-router-dom";

function Lesson() {
  const [lessons, setLessons] = useState<LessonInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [newLessonName, setNewLessonName] = useState<string>("");
  const [newSectionName, setNewSectionName] = useState<string>("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [createLessonLoader, setCreateLessonLoader] = useState(false);
  const [deletingLessonId, setDeletingLessonId] = useState<string | null>(null);
  const navigate = useNavigate();

  // Function for fetching the lessons
  async function fetchLessons() {
    try {
      setLoading(true);
      const response = await lessonService.getAllLessons();
      if (response.success) {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
        setLessons(response.data);
      }
    } catch (error: any) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
      setLessons([]);
    } finally {
      setLoading(false);
    }
  }

  // Function for creating a new lesson
  async function createLesson() {
    setCreateLessonLoader(true);

    if (!newLessonName.trim() || !newSectionName.trim()) {
      setSnackbarOpen(true);
      setSnackbarMessage("Lesson name and section name cannot be empty");
      setCreateLessonLoader(false);
      return;
    }

    try {
      // Normalize section name
      const normalizedSectionName = newSectionName.trim().toLowerCase();

      // Calculate the next available sequence number
      const existingSectionLessons = lessons.filter(
        (lesson) => lesson.section?.toLowerCase() === normalizedSectionName
      );
      const sequence =
        existingSectionLessons.length > 0
          ? Math.max(
              ...existingSectionLessons.map((lesson) => lesson.sequence || 0)
            ) + 1
          : 1;

      const newLessonData = {
        lessonName: newLessonName,
        section: normalizedSectionName,
        sequence: sequence,
        status: "draft",
      };

      const response = await lessonService.createNewLesson(newLessonData);
      if (response.success) {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
        setLessons((prevLessons) => [response.data, ...prevLessons]);
        setNewLessonName("");
        setNewSectionName("");
      }
    } catch (error: any) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    } finally {
      setCreateLessonLoader(false);
    }
  }

  // Function for deleting a lesson
  async function deleteLesson(uuid: string) {
    setDeletingLessonId(uuid);
    try {
      const response = await lessonService.deleteLesson(uuid);
      if (response.success) {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
        setLessons((prevLessons) =>
          prevLessons.filter((lesson) => lesson.uuid !== uuid)
        );
      }
    } catch (error: any) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    } finally {
      setDeletingLessonId(null);
    }
  }

  useEffect(() => {
    fetchLessons();
  }, []);

  function handleCloseSnackbar(): void {
    setSnackbarOpen(false);
  }

  function openLessonDetails(currentLesson: LessonInterface) {
    navigate("/lessondetails", {
      state: {
        lesson: { ...currentLesson },
      },
    });
  }

  // Group lessons by section and sort by sequence
  const groupedLessons = lessons.reduce((groups, lesson) => {
    const sectionKey = lesson.section?.trim().toLowerCase() || "uncategorized";
    if (!groups[sectionKey]) {
      groups[sectionKey] = [];
    }
    groups[sectionKey].push(lesson);
    return groups;
  }, {} as Record<string, LessonInterface[]>);

  // Sort lessons in each section by sequence
  Object.keys(groupedLessons).forEach((section) => {
    groupedLessons[section].sort(
      (a, b) => (a.sequence || 0) - (b.sequence || 0)
    );
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "90vh",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <TopAppBar />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexGrow: 1,
          padding: 2,
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: 2, textAlign: "center" }}>
          Lesson Management
        </Typography>

        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
            maxWidth: "600px",
            overflowY: "auto",
            padding: 2,
            border: "1px solid #ddd",
            borderRadius: "8px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            maxHeight: "60vh",
          }}
        >
          {loading ? (
            <Container
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Container>
          ) : (
            Object.entries(groupedLessons).map(([section, lessons]) => (
              <Box key={section} sx={{ marginBottom: 4 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", marginBottom: 2 }}
                >
                  {section}
                </Typography>
                {lessons.map((lesson) => (
                  <Card
                    key={lesson.uuid}
                    sx={{
                      marginBottom: 2,
                      borderRadius: "8px",
                      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      openLessonDetails(lesson);
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        {lesson.lessonName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Sequence: {lesson.sequence} | Status: {lesson.status}
                      </Typography>
                    </CardContent>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteLesson(lesson.uuid);
                      }}
                      disabled={deletingLessonId === lesson.uuid}
                    >
                      {deletingLessonId === lesson.uuid ? (
                        <CircularProgress size={24} />
                      ) : (
                        <DeleteIcon />
                      )}
                    </IconButton>
                  </Card>
                ))}
              </Box>
            ))
          )}
        </Box>

        <Box
          sx={{
            marginTop: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            maxWidth: "600px",
          }}
        >
          <TextField
            label="Section Name"
            variant="outlined"
            fullWidth
            value={newSectionName}
            onChange={(e) => setNewSectionName(e.target.value)}
          />
          <TextField
            label="New Lesson Name"
            variant="outlined"
            fullWidth
            value={newLessonName}
            onChange={(e) => setNewLessonName(e.target.value)}
          />
          {createLessonLoader ? (
            <Container>
              <CircularProgress />
            </Container>
          ) : (
            <Button variant="contained" color="primary" onClick={createLesson}>
              Create Lesson
            </Button>
          )}
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </Box>
  );
}

export default Lesson;
