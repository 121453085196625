import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  CircularProgress,
  Snackbar,
  IconButton,
  Container,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TopAppBar from "../modules/TopAppBar";
import { useEffect, useState } from "react";

import { abTestService } from "../services/abtesting.service";
import { useNavigate } from "react-router-dom";
export interface Variant {
  identifier?: string;
  allocation?: number;
  variables?: Record<string, any>; // Allows any JSON structure for variables
  description?: string;
}

export interface ExperimentInterface {
  _id: string;
  experimentName: string;
  identifier?: string;
  variants: Variant[];
  status: string;
}
function Experiments() {
  const [experiments, setExperiments] = useState<ExperimentInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [newExperimentName, setNewExperimentName] = useState<string>("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [createExperimentLoader, setCreateExperimentLoader] = useState(false);
  const [deletingExperimentId, setDeletingExperimentId] = useState<
    string | null
  >(null);
  const navigate = useNavigate();

  // Fetch experiments
  async function fetchExperiments() {
    try {
      setLoading(true);
      const response = await abTestService.fetchExperiments();

      if (response.success) {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
        setExperiments(response.experimentData);
      }
    } catch (error: any) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
      setExperiments([]);
    } finally {
      setLoading(false);
    }
  }

  // Create new experiment
  async function createExperiment() {
    setCreateExperimentLoader(true);

    if (!newExperimentName.trim()) {
      setSnackbarOpen(true);
      setSnackbarMessage("Experiment name cannot be empty");
      setCreateExperimentLoader(false);
      return;
    }

    try {
      const newExperimentData = {
        experimentName: newExperimentName.trim(),
        status: false,
      };

      const response = await abTestService.createNewExperiment(
        newExperimentData
      );
      if (response.success) {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
        setExperiments((prevExperiments) => [
          response.experimentData,
          ...prevExperiments,
        ]);
        setNewExperimentName("");
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
      }
    } catch (error: any) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    } finally {
      setCreateExperimentLoader(false);
    }
  }

  // Delete an experimen
  async function deleteExperiment(_id: string) {
    setDeletingExperimentId(_id);
    try {
      const response = await abTestService.deleteExperiment(_id);
      if (response.success) {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
        setExperiments((prevExperiments) =>
          prevExperiments.filter((experiment) => experiment._id !== _id)
        );
      }
    } catch (error: any) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    } finally {
      setDeletingExperimentId(null);
    }
  }

  useEffect(() => {
    fetchExperiments();
  }, []);

  function handleCloseSnackbar(): void {
    setSnackbarOpen(false);
  }
  function openExperimentDetails(currentExperiment: ExperimentInterface) {
    const { _id, ...rest } = currentExperiment; // Extract _id from the experiment data
    navigate(`/experimentdetails?experiment_id=${_id}`);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "90vh",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <TopAppBar />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexGrow: 1,
          padding: 2,
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: 2, textAlign: "center" }}>
          Experiments Management
        </Typography>

        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
            maxWidth: "600px",
            overflowY: "auto",
            padding: 2,
            border: "1px solid #ddd",
            borderRadius: "8px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            maxHeight: "60vh",
          }}
        >
          {loading ? (
            <Container
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Container>
          ) : experiments && experiments.length > 0 ? (
            experiments.map((experiment) => (
              <Card
                key={experiment._id}
                sx={{
                  marginBottom: 2,
                  borderRadius: "8px",
                  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  openExperimentDetails(experiment);
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {experiment.experimentName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Status: {experiment.status ? "On" : "Off"}
                  </Typography>
                </CardContent>
                <IconButton
                  aria-label="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteExperiment(experiment._id);
                  }}
                  disabled={deletingExperimentId === experiment._id}
                >
                  {deletingExperimentId === experiment._id ? (
                    <CircularProgress size={24} />
                  ) : (
                    <DeleteIcon />
                  )}
                </IconButton>
              </Card>
            ))
          ) : (
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ textAlign: "center", marginTop: 2 }}
            >
              No experiments available.
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            marginTop: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            maxWidth: "600px",
          }}
        >
          <TextField
            label="New Experiment Name"
            variant="outlined"
            fullWidth
            value={newExperimentName}
            onChange={(e) => setNewExperimentName(e.target.value)}
          />
          {createExperimentLoader ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={createExperiment}
            >
              Create Experiment
            </Button>
          )}
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </Box>
  );
}

export default Experiments;
