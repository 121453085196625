/** @format */
import { QueueServiceClient } from "@azure/storage-queue";
import { accountService } from "../services";
import { notificationService } from "../services/notification.service";
import { DateRange } from "@mui/icons-material";
import { FilterInterface } from "../interface/FilterInterface";
import { fi } from "date-fns/locale";
export const notificationUtils = {
  chunkArray,
  sendMessageToQueue,
  getTotalUsers,
  finalUserToSendNotification,
  getCsvFileCall,
};

/// Convert all the users in chunks of users-------------------------/
async function chunkArray(array: any, size: number, type: string) {
  // Extract the userId field from each object
  if (type === "email") {
    const userEmail = array.map((item: any) => item.email);

    const chunkedArray = [];
    for (let i = 0; i < userEmail.length; i += size) {
      chunkedArray.push(userEmail.slice(i, i + size));
    }

    return chunkedArray;
  } else {
    // Removing the 'email' key from each notification
    const fcmTokens = array
      .filter((data: any) => data.fcmToken != null) // Filter out entries with null or undefined fcmToken
      .map((data: any) => data.fcmToken); // Map to only the fcmToken value

    const chunkedArray = [];
    for (let i = 0; i < fcmTokens.length; i += size) {
      chunkedArray.push(fcmTokens.slice(i, i + size));
    }

    return chunkedArray;
  }
}

function utf8ToBase64(str: any) {
  const utf8Bytes = new TextEncoder().encode(str);
  const binaryString = new Uint8Array(utf8Bytes).reduce(
    (acc, byte) => acc + String.fromCharCode(byte),
    ""
  );
  return btoa(binaryString);
}

/// Storing the message in queue--------------------------------------/

async function sendMessageToQueue(
  queueName: any,
  message: any,
  queuestring: any
) {
  try {
    const connectionString = queuestring;

    const queueServiceClient =
      QueueServiceClient.fromConnectionString(connectionString);
    // Get a client for the specific queue
    const queueClient = queueServiceClient.getQueueClient(queueName);

    const messageBase64 = utf8ToBase64(message);
    await queueClient.sendMessage(messageBase64);
    console.log("storing in queue successfull");
  } catch (error) {
    console.log(`error in stroing in queue ${error}`);
  }
}

/// Async function for getting all the user in notification and email based on the filters----------------------------------/
async function getTotalUsers(numberofCalls: number, filters: FilterInterface) {
  const user = accountService.userValue;
  /// Get all the user's who are matching the filteration on the frontend part---/
  const params = {
    numberofCalls: numberofCalls,
    deviceType: filters.deviceType, // Add device type if active
    onboarding: filters.onboardingFilter, // Add onboarding status if active
    subscription: filters.subscriptionFilter, // Add subscription status if active
    trainerCode: user.role === "admin" ? undefined : user.uniqueTrainerCode, // Add trainer code if user is not admin
    countries: filters.checkBoxCountries, // Include selected countries in the request
    notification: filters.notificationFilter,
    maxVersion: filters.maxVersion,
    minVersion: filters.minVersion,
    genderType: filters.genderType,
    goalType: filters.goalType,
    activeType: filters.activeType,
    userId: user.id,
    activity: filters.activity,
    dateRange: filters.dateRange,
    contactNumber: filters.contactNumberFilter,
    notes: filters.notesFilter,
    uncheckedCountries: filters.uncheckCountries,
    messageSeen: filters.messageSeenFilter,
    countryCode: filters.countryCode,
    csvFile: filters.csvFileFilter,
    distinctIds: filters.distinctIds,
    minimumAge: filters.minimumAge,
    maximumAge: filters.maximumAge,
    maximumBmi: filters.maximumBmi,
    minimumBmi: filters.minimumBmi,
    freePremium: filters.freePremiumFilter,
    freeTrial: filters.freeTrialFilter,
    paidFilter: filters.paidFilter,
    signUpFilter: filters.signUpFilter,
    subscriptionCanceledFilter: filters.subscriptionCanceledFilter,
    initialQuotaFilter: filters.initialQuotaFilter,
    hasGymMembershipFilter: filters.hasGymMembershipFilter,
    freePremiumOverFilter: filters.freePremiumOverFilter,
    weighingScaleFilter: filters.weighingScaleFilter,
  };

  const userGetResponse = await accountService.getAllUsers(params);

  let { users, userCount, passwordMatch } = userGetResponse;

  if (users) {
    return users;
  } else {
    return [];
  }
}

/// Async function for getting all the user in notification and email based on the filters----------------------------------/
async function getCsvFileCall(numberofCalls: number, filters: FilterInterface) {
  const user = accountService.userValue;
  /// Get all the user's who are matching the filteration on the frontend part---/
  const params = {
    numberofCalls: numberofCalls,

    allFields: undefined,
    csvFile: filters.csvFileFilter,
    distinctIds: filters.distinctIds,
  };

  const userGetResponse = await accountService.getCsvUsers(params);

  let { users, userCount, passwordMatch } = userGetResponse;

  if (users) {
    return users;
  } else {
    return [];
  }
}
//// Function to filter the user who has got successfull entry in the notification database based on title----------------------/
async function finalUserToSendNotification(
  fetchedUser: any,
  avoidDuplicateCall: boolean,
  title: string
) {
  try {
    if (avoidDuplicateCall) {
      const notificationUsers = await notificationService.getNotificationUser(
        title
      );

      if (notificationUsers.userArray.length > 0) {
        const notificationUserIds = new Set(
          notificationUsers.userArray.map((user: any) => user.user)
        );

        const filteredUsers = fetchedUser.filter(
          (user: any) =>
            !notificationUserIds.has(user.userId) && user.fcmToken != null
        );

        return {
          message: "Users has been filterted succesfully",
          success: true,
          users: filteredUsers,
        };
      } else {
        return {
          message: "Users has been filterted succesfully",
          success: true,
          users: fetchedUser,
        };
      }
    } else {
      /// when duplicated call is false then we do not do the filteration just returned the fetchedUser
      return {
        message: "Users has been filterted succesfully",
        success: true,
        users: fetchedUser,
      };
    }
  } catch (error) {
    console.log(
      `some error in removing the user which are in notification ${error}`
    );

    return {
      message: "some error in removing the user which are in notification",
      success: false,
      users: null,
    };
  }
}
