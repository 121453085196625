/** @format */

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { memo, useEffect, useState } from "react";
import * as yup from "yup";
import { accountService } from "../../services";
import { validationSchemaForOldVersion } from "../../utils/AppSettings";
// interface OldVersion{
//     toggleAndroidInput:()=>void;
//     androidInputOpen:boolean;
//     submitLoading:boolean;
//     toggleIosInput:()=>void;
//     iosInputOpen:boolean;
//     iosVersions:any;
//     androidVersions:any
// }
interface OldVersion {
  releaseNote: any;
  setReleaseNote: any;
}
function OldVersion({ releaseNote, setReleaseNote }: OldVersion) {
  const [androidVersions, setAndroidVersions] = useState<any>([]);

  const [iosVersions, setIosVersions] = useState<any>([]);

  const [loading, setLoading] = useState(true);
  const [androidInputOpen, setAndroidInputOpen] = useState(false);

  const [iosInputOpen, setIosInputOpen] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const toggleAndroidInput = () => {
    setAndroidInputOpen(!androidInputOpen);
    if (iosInputOpen) {
      setIosInputOpen(false);
    }
  };

  const toggleIosInput = () => {
    setIosInputOpen(!iosInputOpen);
    if (androidInputOpen) {
      setAndroidInputOpen(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachebusterAndroid = Math.floor(Math.random() * 100) + 1;
        const cachebusterIos = Math.floor(Math.random() * 200) + 1;
        const storageNameResponse = await accountService.getStorageName();

        const androidVersionUrl = `https://${storageNameResponse.storageName}.blob.core.windows.net/reshape-public/versioning-android.json?cachebuster=${cachebusterAndroid}`;
        const androidReleaseNoteUrl = `https://${storageNameResponse.storageName}.blob.core.windows.net/reshape-public/release-notes-android.json?cachebuster=${cachebusterAndroid}`;
        const iosVersionUrl = `https://${storageNameResponse.storageName}.blob.core.windows.net/reshape-public/versioning-ios.json?cachebuster=${cachebusterIos}`;
        const iosReleaseNoteUrl = `https://${storageNameResponse.storageName}.blob.core.windows.net/reshape-public/release-notes-ios.json?cachebuster=${cachebusterIos}`;

        // Fetch all URLs in parallel
        const [
          androidVersionResponse,
          androidReleaseNoteResponse,
          iosVersionResponse,
          iosReleaseNoteResponse,
        ] = await Promise.all([
          fetch(androidVersionUrl),
          fetch(androidReleaseNoteUrl),
          fetch(iosVersionUrl),
          fetch(iosReleaseNoteUrl),
        ]);

        // Parse JSON responses
        const [androidData, androidReleaseNote, iosData, iosReleaseNote] =
          await Promise.all([
            androidVersionResponse.json(),
            androidReleaseNoteResponse.json(),
            iosVersionResponse.json(),
            iosReleaseNoteResponse.json(),
          ]);

        // Update state with the fetched data
        setReleaseNote({ android: androidReleaseNote, ios: iosReleaseNote });
        setAndroidVersions(androidData);
        setIosVersions(iosData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (values: any, actions: any, type: any) => {
    setSubmitLoading(true);
    try {
      const { version, releaseNote } = values;
      const newVersion = version.trim();
      const releaseNotes = releaseNote.trim();
      let newValue = true;
      if (newVersion) {
        let updatedVersions: any[] = [];
        if (type === "android") {
          setReleaseNote((value: any) => ({
            ...value,
            android: releaseNotes,
          }));
          for (let i = 0; i < androidVersions.length; i++) {
            if (androidVersions[i] === values.version) {
              newValue = false;
            } else {
              updatedVersions = [newVersion, ...androidVersions];
              setAndroidVersions(updatedVersions);
            }
          }
        } else if (type === "ios") {
          setReleaseNote((value: any) => ({
            ...value,
            ios: releaseNotes,
          }));
          for (let i = 0; i < iosVersions.length; i++) {
            if (iosVersions[i] === values.version) {
              newValue = false;
            } else {
              updatedVersions = [newVersion, ...iosVersions];
              setIosVersions(updatedVersions);
            }
          }
        }

        if (newValue === true) {
          const updateResponse = await accountService.updateVersion(
            type,
            values.version,
            releaseNotes
          );

          if (updateResponse.success === true) {
            actions.resetForm();
            if (type === "android") {
              toggleAndroidInput(); // Close the input section
            } else if (type === "ios") {
              toggleIosInput(); // Close the input section
            }
            alert(updateResponse.message);
          } else {
            actions.resetForm();
            if (type === "android") {
              toggleAndroidInput(); // Close the input section
            } else if (type === "ios") {
              toggleIosInput(); // Close the input section
            }
            alert(updateResponse.message);
          }
        } else {
          actions.resetForm();
          alert("Kindly write a new version");
        }
      }
      setSubmitLoading(false);
    } catch (error) {
      console.error("Error submitting version data:", error);
    }
  };
  return (
    <>
      <Box p={4} style={{ backgroundColor: "#f0f0f0" }}>
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              marginTop: 10,
              marginBottom: 10,
              fontSize: 20,
              fontWeight: "700",
            }}
          >
            Old Versioning
          </Typography>
        </Container>
        {loading ? (
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Container>
        ) : (
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Box
                border={1}
                borderRadius={5}
                p={3}
                style={{ backgroundColor: "#fff", position: "relative" }}
              >
                <Button
                  onClick={toggleAndroidInput}
                  variant="contained"
                  color="primary"
                  style={{ position: "absolute", top: 10, right: 10 }}
                >
                  {androidInputOpen ? "Close" : "Add Version"}
                </Button>
                <Typography variant="h6">Android</Typography>
                <Typography variant="body1" style={{ marginTop: 10 }}>
                  Current Version: {androidVersions[0]}
                </Typography>
                <Box
                  display="flex"
                  alignItems="flex-start" // Adjust to align items at the start
                  style={{ marginTop: 10 }}
                >
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{ whiteSpace: "nowrap", marginRight: 1 }}
                  >
                    Release Note:
                  </Typography>
                  <Box
                    sx={{
                      overflow: "auto", // Enables scrolling,
                      height: 50,
                      marginLeft: 1,
                      width: "100%", // Ensure it takes up the remaining space
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ textAlign: "justify" }}
                    >
                      {releaseNote.android}
                    </Typography>
                  </Box>
                </Box>
                {androidInputOpen && (
                  <Formik
                    initialValues={{ version: "", releaseNote: "" }}
                    validationSchema={validationSchemaForOldVersion}
                    onSubmit={(values, actions) =>
                      handleSubmit(values, actions, "android")
                    }
                  >
                    {({ handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <Field name="version">
                          {({ field, meta }: any) => (
                            <TextField
                              {...field}
                              label="Version"
                              multiline={true}
                              rows={1}
                              variant="outlined"
                              fullWidth
                              style={{ marginTop: "25px" }}
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                        <Field name="releaseNote">
                          {({ field, meta }: any) => (
                            <TextField
                              {...field}
                              label="Release Note"
                              variant="outlined"
                              multiline={true}
                              fullWidth
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                              style={{ marginTop: 20 }}
                              rows={4}
                            />
                          )}
                        </Field>
                        {submitLoading ? (
                          <Container>
                            <CircularProgress style={{ marginTop: "10px" }} />
                          </Container>
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "20px" }}
                          >
                            Submit
                          </Button>
                        )}
                      </Form>
                    )}
                  </Formik>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                border={1}
                borderRadius={5}
                p={3}
                style={{ backgroundColor: "#fff", position: "relative" }}
              >
                <Button
                  onClick={toggleIosInput}
                  variant="contained"
                  color="primary"
                  style={{ position: "absolute", top: 10, right: 10 }}
                >
                  {iosInputOpen ? "Close" : "Add Version"}
                </Button>
                <Typography variant="h6">IOS</Typography>
                <Typography variant="body1" style={{ marginTop: 10 }}>
                  Current Version: {iosVersions[0]}
                </Typography>
                <Box
                  display="flex"
                  alignItems="flex-start" // Adjust to align items at the start
                  style={{ marginTop: 10 }}
                >
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{ whiteSpace: "nowrap", marginRight: 1 }}
                  >
                    Release Note:
                  </Typography>
                  <Box
                    sx={{
                      overflow: "auto", // Enables scrolling
                      height: 50,
                      marginLeft: 1,
                      width: "100%", // Ensure it takes up the remaining space
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ textAlign: "justify" }}
                    >
                      {releaseNote.ios}
                    </Typography>
                  </Box>
                </Box>
                {iosInputOpen && (
                  <Formik
                    initialValues={{ version: "", releaseNote: "" }}
                    validationSchema={validationSchemaForOldVersion}
                    onSubmit={(values, actions) =>
                      handleSubmit(values, actions, "ios")
                    }
                  >
                    {({ handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <Field name="version">
                          {({ field, meta }: any) => (
                            <TextField
                              {...field}
                              label="Version"
                              variant="outlined"
                              fullWidth
                              style={{ marginTop: "25px" }}
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                        <Field name="releaseNote">
                          {({ field, meta }: any) => (
                            <TextField
                              {...field}
                              label="Release Note"
                              rows={4}
                              variant="outlined"
                              fullWidth
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                              multiline={true}
                              style={{ marginTop: 20 }}
                            />
                          )}
                        </Field>
                        {submitLoading ? (
                          <Container>
                            <CircularProgress style={{ marginTop: "10px" }} />
                          </Container>
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "20px" }}
                          >
                            Submit
                          </Button>
                        )}
                      </Form>
                    )}
                  </Formik>
                )}
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}

export default memo(OldVersion);
