/** @format */

import { fetchWrapper } from "../helpers";
import { config } from "../shared/constants/config";
import {
  InAppReviewInterface,
  SubscriptionSettingsInterface,
} from "../interface/SubscriptionSettingsInterface";
import { accountService } from "./account.service";
import GetStartedNotificationConfig from "../Components/AppSettings/NotificationContent";
const baseUrl = `${config.apiUrl}/api`;

export const settingsService = {
  getSubscriptionSettings,
  getInAppReview,
  updateSubscriptionSettings,
  updatePromptSettings,
  updateLearnSettings,
  updateOnboardingConfig,
  updateInAppReview,
  updateWeeklyReportSettings,
  updateCampaignJson,
  updateAppFeatures,
  updateGetStartedNotificationContent,
};

//// Function for the updating the weekly report json file updating
async function updateWeeklyReportSettings(weeklyReportData: any) {
  return fetchWrapper.post(`${baseUrl}/update-weeklyreport-json`, {
    ...weeklyReportData,
  });
}
//// Function for the updating the campaign  json file updating
async function updateCampaignJson(capaignJsonData: any) {
  return fetchWrapper.post(`${baseUrl}/update-campaignevent-json`, {
    ...capaignJsonData,
  });
}
async function getSubscriptionSettings() {
  // New: GET request to the specified endpoint
  const cachebuster = Math.floor(Math.random() * 200) + 1;
  const storageNameResponse = await accountService.getStorageName();
  const response = await fetch(
    `https://${storageNameResponse.storageName}.blob.core.windows.net/reshape-public/subscriptionSettingsCountries.json?cachebuster=${cachebuster}`
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json(); // Assuming the response is JSON

  // Here you can combie or manipulate the data from both requests as needed
  // For this examnple, we're just returning the JSON data from the GET request
  return data;
}

/// Function for getting the the data of in app review json
async function getInAppReview() {
  // New: GET request to the specified endpoint
  const storageNameResponse = await accountService.getStorageName();
  const response = await fetch(
    `https://${storageNameResponse.storageName}.blob.core.windows.net/reshape-public/in-app-review.json`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await response.json(); // Assuming the response is JSON

  // Here you can combie or manipulate the data from both requests as needed
  // For this examnple, we're just returning the JSON data from the GET request
  return data;
}

//// function for updating the subscription data---------------------------/
function updateSubscriptionSettings(
  subscriptionData: SubscriptionSettingsInterface[]
) {
  return fetchWrapper.post(`${baseUrl}/update-subscription-settings`, {
    subscriptionData,
  });
}
function updateAppFeatures(appFeatures: any) {
  return fetchWrapper.put(`${baseUrl}/update-app-features`, {
    appFeatures,
  });
}
//// function for updating the subscription data---------------------------/
async function updatePromptSettings(promptData: any) {
  return fetchWrapper.post(`${baseUrl}/prompt-settings`, {
    ...promptData,
  });
}

//// function for updating the subscription data---------------------------/
async function updateOnboardingConfig(onboardingData: any) {
  return fetchWrapper.post(`${baseUrl}/onboarding-config`, {
    ...onboardingData,
  });
}

//// function for updating the get started notification content data -------------/
async function updateGetStartedNotificationContent(
  getStartedNotificationContent: any
) {
  return fetchWrapper.post(`${baseUrl}/update-getstartednotification-content`, {
    ...getStartedNotificationContent,
  });
}

//// function for updating the learn setting---------------------------/
async function updateLearnSettings(promptData: any) {
  return fetchWrapper.post(`${baseUrl}/learn-settings`, {
    ...promptData,
  });
}

//// function for updating the In App review---------------------------/
async function updateInAppReview(inAppReviewData: InAppReviewInterface[]) {
  return fetchWrapper.post(`${baseUrl}/in-app-review`, {
    ...inAppReviewData,
  });
}
