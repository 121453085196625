/** @format */

import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  Grid,
  TextField,
  Button,
  Snackbar,
  Box,
  Typography,
} from "@mui/material";
import { Formik, Form, Field, FieldArray, FormikHelpers } from "formik";
import * as yup from "yup";
import { settingsService } from "../../services/settings.service";

interface Notification {
  id: string;
  title: string;
  body: string;
}

interface InitialValues {
  notifications: Notification[];
}

const validationSchema = yup.object().shape({
  notifications: yup.array().of(
    yup.object().shape({
      title: yup.string().required("Title is required"),
      body: yup.string().required("Body is required"),
    })
  ),
});

const GetStartedNotificationConfig: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState<InitialValues>({
    notifications: [],
  });
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch(
          "https://reshapestorage.blob.core.windows.net/reshape-public/get-started-notifications-content.json"
        );
        const data: Record<string, { title: string; body: string }> =
          await response.json();

        const formattedData: Notification[] = Object.entries(data).map(
          ([key, value]) => ({
            id: key,
            title: value.title,
            body: value.body,
          })
        );

        setInitialValues({ notifications: formattedData });
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
        setSnackbarMessage(
          "Failed to fetch notifications. Please try again later."
        );
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const handleSubmit = async (
    values: InitialValues,
    { setSubmitting }: FormikHelpers<InitialValues>
  ) => {
    setSubmitLoading(true);
    try {
      const formattedData: Record<string, { title: string; body: string }> =
        values.notifications.reduce((acc: any, item) => {
          acc[item.id] = { title: item.title, body: item.body };
          return acc;
        }, {});

      const updateResponse =
        await settingsService.updateGetStartedNotificationContent(
          formattedData
        );
      const { message, status } = updateResponse;
      if (status === true) {
        setSnackbarOpen(true);
        setSnackbarMessage(message);
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(message);
      }
    } catch (error) {
      console.error("Failed to update notifications:", error);
    }
    setSubmitLoading(false);
    setSubmitting(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box p={4} style={{ backgroundColor: "#f0f0f0" }}>
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
          marginBottom: 20,
        }}
      >
        <Typography sx={{ fontSize: 20, fontWeight: "700" }}>
          Notifications Config
        </Typography>
      </Container>
      {loading ? (
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <FieldArray
                name="notifications"
                render={() => (
                  <Grid container spacing={3} justifyContent="center">
                    {values.notifications.map((notification, index) => (
                      <Grid item xs={12} md={6} key={notification.id}>
                        <Typography
                          sx={{ fontSize: 16, fontWeight: "bold", mb: 2 }}
                        >
                          {notification.id.replace(/_/g, " ").toUpperCase()}
                        </Typography>
                        <Field
                          as={TextField}
                          name={`notifications.${index}.title`}
                          label="Title"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={2}
                        />
                        <Box mt={2}>
                          <Field
                            as={TextField}
                            name={`notifications.${index}.body`}
                            label="Body"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                          />
                        </Box>
                      </Grid>
                    ))}
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {submitLoading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={isSubmitting}
                          style={{ marginTop: 20, width: "82%" }}
                        >
                          Submit
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                )}
              />
            </Form>
          )}
        </Formik>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </Box>
  );
};

export default GetStartedNotificationConfig;
