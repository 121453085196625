import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  IconButton,
  Checkbox,
  Snackbar,
  Typography,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  Container,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import TopAppBar from "../modules/TopAppBar";
import { useLocation } from "react-router-dom";
import { lessonService } from "../services/lesson.service";
import { LessonPages } from "../interface/LessonInterface";
import { additionalService } from "../services/additional.service";

interface Option {
  id: number;
  text: string;
}

interface Field {
  sequence: number; // Use sequence instead of id
  type:
    | "paragraph"
    | "quizSingle"
    | "quizMultiple"
    | "quizFreeText"
    | "paragraphBanner";
  input_fields: {
    title?: string;
    options?: Option[];
    answers?: number[];
    correctAnswer?: string;
    wrongAnswer?: string;
    paragraph?: string;
  };
}
interface PageContentInterface {
  pages: LessonPages[];
  setPages: React.Dispatch<React.SetStateAction<LessonPages[]>>;
  currentOpenedPageIndex: number | null;
}
export default function LessonPage({
  pages,
  currentOpenedPageIndex,
  setPages,
}: PageContentInterface) {
  console.log("this is pages");
  console.log(pages);

  const location = useLocation();
  const pageDetails = location?.state;
  const currentPage =
    currentOpenedPageIndex !== null ? pages[currentOpenedPageIndex] : null;

  const [pageName, setPageName] = useState<string>(pageDetails?.pageName || "");
  const [bannerImage, setBannerImage] = useState<string | undefined>(
    pageDetails?.pageBannerImage || ""
  );
  const [pageButtonText, setPageButtonText] = useState<string>(
    pageDetails?.pageButtonText || ""
  );
  const [fields, setFields] = useState<Field[]>([]);
  const [selectedField, setSelectedField] = useState<Field["type"] | "">("");
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (currentPage) {
      setPageName(currentPage.pageName);
      setPageButtonText(currentPage.pageButtonText);
      setBannerImage(currentPage.pageBannerImage);

      // Process `pageContent` if it exists
      if (currentPage.pageContent && currentPage.pageContent.length > 0) {
        const parsedFields = currentPage.pageContent.map((field) => ({
          ...field,
          input_fields:
            typeof field.input_fields === "string"
              ? JSON.parse(field.input_fields) // Parse if it's a string
              : field.input_fields, // Use directly if it's already an object
        }));
        setFields(parsedFields); // Update fields state
      } else {
        setFields([]); // Reset fields if `pageContent` is empty
      }
    }
  }, [currentPage]);
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setLoading(true);
      try {
        // Show a loader or disable UI to indicate uploading
        setSnackbarMessage("Uploading image...");

        // Upload the image to Azure
        const uploadedImageUrl =
          await additionalService.uploadImageOnAzureForLesson(file);

        // Update the banner image state with the uploaded URL
        setBannerImage(uploadedImageUrl);

        setSnackbarMessage("Image uploaded successfully!");
      } catch (error) {
        console.error("Error uploading image:", error);
        setSnackbarMessage("Failed to upload image. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleAddField = () => {
    if (selectedField) {
      const newField: Field = {
        sequence: fields.length + 1,
        type: selectedField,
        input_fields: {},
      };

      if (selectedField === "quizSingle" || selectedField === "quizMultiple") {
        newField.input_fields = {
          title: "",
          options: [],
          answers: [],
          correctAnswer: "",
          wrongAnswer: "",
        };
      } else if (selectedField === "quizFreeText") {
        newField.input_fields = {
          title: "",
          paragraph: "",
        };
      } else if (selectedField === "paragraphBanner") {
        newField.input_fields = {
          title: "",
        };
      }

      setFields([...fields, newField]);
      setSelectedField("");
    }
  };

  const handleDeleteField = (sequence: number) => {
    setFields(fields.filter((field) => field.sequence !== sequence));
  };

  const handleAddOption = (sequence: number) => {
    setFields(
      fields.map((field) =>
        field.sequence === sequence
          ? {
              ...field,
              input_fields: {
                ...field.input_fields,
                options: [
                  ...(field.input_fields.options || []),
                  {
                    id: (field.input_fields.options?.length || 0) + 1,
                    text: "",
                  },
                ],
              },
            }
          : field
      )
    );
  };

  const handleFieldChange = (
    sequence: number,
    key: keyof Field["input_fields"],
    value: string
  ) => {
    setFields(
      fields.map((field) =>
        field.sequence === sequence
          ? {
              ...field,
              input_fields: {
                ...field.input_fields,
                [key]: value,
              },
            }
          : field
      )
    );
  };

  const handleOptionChange = (
    sequence: number,
    optionId: number,
    text: string
  ) => {
    setFields(
      fields.map((field) =>
        field.sequence === sequence
          ? {
              ...field,
              input_fields: {
                ...field.input_fields,
                options: field.input_fields.options?.map((option) =>
                  option.id === optionId ? { ...option, text } : option
                ),
              },
            }
          : field
      )
    );
  };

  const handleToggleAnswer = (sequence: number, optionId: number) => {
    setFields(
      fields.map((field) => {
        if (field.sequence === sequence) {
          const isSelected = field.input_fields.answers?.includes(optionId);
          let updatedAnswers = field.input_fields.answers || [];
          if (field.type === "quizSingle") {
            updatedAnswers = isSelected ? [] : [optionId];
          } else if (field.type === "quizMultiple") {
            updatedAnswers = isSelected
              ? updatedAnswers.filter((id) => id !== optionId)
              : [...updatedAnswers, optionId];
          }
          return {
            ...field,
            input_fields: {
              ...field.input_fields,
              answers: updatedAnswers,
            },
          };
        }
        return field;
      })
    );
  };

  const validateFields = () => {
    for (const field of fields) {
      if (!field.input_fields.title && field.type !== "paragraph") {
        setSnackbarMessage(
          `Title is required for ${field.type} Sequence: ${field.sequence}`
        );
        return false;
      }

      if (
        ["quizSingle", "quizMultiple"].includes(field.type) &&
        (!field.input_fields.answers || field.input_fields.answers.length === 0)
      ) {
        setSnackbarMessage(
          `Please select at least one answer for Quiz Sequence: ${field.sequence}`
        );
        return false;
      }

      if (
        ["quizSingle", "quizMultiple"].includes(field.type) &&
        (!field.input_fields.correctAnswer || !field.input_fields.wrongAnswer)
      ) {
        setSnackbarMessage(
          `Correct and Wrong answers are required for Quiz Sequence: ${field.sequence}`
        );
        return false;
      }

      if (field.type === "quizFreeText" && !field.input_fields.paragraph) {
        setSnackbarMessage(
          `Paragraph is required for Free Text Quiz Sequence: ${field.sequence}`
        );
        return false;
      }
    }
    return true;
  };

  const handleUpdate = async () => {
    let transformFields = fields.map((item: any) => JSON.stringify(item));
    if (currentPage) {
      const updatedPage = {
        ...currentPage,
        pageName: pageName,
        pageBannerImage: bannerImage,
        pageButtonText: pageButtonText,
        pageContent: fields,
      };

      setPages((prevPages) =>
        prevPages.map((page, index) =>
          index === currentOpenedPageIndex ? updatedPage : page
        )
      );

      setSnackbarMessage("Page updated successfully!");
    }
  };

  return (
    <Box sx={{ height: "80vh", overflowY: "scroll", overflowX: "hidden" }}>
      <Box sx={{ padding: 2 }}>
        <TextField
          label="Page Name"
          fullWidth
          variant="outlined"
          value={pageName}
          onChange={(e) => setPageName(e.target.value)}
        />

        <Box sx={{ marginY: 2, display: "flex", flexDirection: "column" }}>
          <Typography>Upload Banner Image</Typography>
          {loading ? (
            <Container>
              <CircularProgress />
            </Container>
          ) : (
            <Button variant="contained" component="label">
              Upload Image
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleFileUpload}
              />
            </Button>
          )}

          {bannerImage && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              <img
                src={bannerImage}
                alt="Banner Preview"
                style={{
                  width: "150px", // Set a smaller width
                  height: "auto", // Maintain aspect ratio
                  borderRadius: "8px", // Optional: Add rounded corners
                  boxShadow: "0 2px 5px rgba(0,0,0,0.2)", // Optional: Add a subtle shadow
                }}
              />
              <IconButton
                aria-label="remove image"
                onClick={() => setBannerImage("")} // Clear the image URL
                sx={{
                  marginLeft: 1,
                  backgroundColor: "white",
                  border: "1px solid #ddd",
                  "&:hover": { backgroundColor: "#f8f8f8" },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </Box>

        <TextField
          label="Button Text"
          fullWidth
          variant="outlined"
          value={pageButtonText}
          onChange={(e) => setPageButtonText(e.target.value)}
        />

        <Box sx={{ display: "flex", gap: 2, marginY: 2 }}>
          <Select
            value={selectedField}
            onChange={(e) => setSelectedField(e.target.value as Field["type"])}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>
              Select Input Type
            </MenuItem>
            <MenuItem value="paragraph">Paragraph</MenuItem>
            <MenuItem value="quizSingle">Quiz (Single Answer)</MenuItem>
            <MenuItem value="quizMultiple">Quiz (Multiple Answers)</MenuItem>
            <MenuItem value="quizFreeText">Quiz (Free Text)</MenuItem>
            <MenuItem value="paragraphBanner">Paragraph Banner</MenuItem>
          </Select>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddField}
            disabled={!selectedField}
          >
            Add Field
          </Button>
        </Box>

        {fields.map((field) => (
          <Card key={field.sequence} sx={{ marginBottom: 2 }}>
            <CardContent>
              <Typography variant="h6">{field.type}</Typography>

              <TextField
                label="Title"
                fullWidth
                variant="outlined"
                value={field.input_fields.title || ""}
                onChange={(e) =>
                  handleFieldChange(field.sequence, "title", e.target.value)
                }
                sx={{ marginY: 2 }}
              />

              {["quizSingle", "quizMultiple"].includes(field.type) && (
                <>
                  <TextField
                    label="Correct Answer"
                    fullWidth
                    variant="outlined"
                    value={field.input_fields.correctAnswer || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        field.sequence,
                        "correctAnswer",
                        e.target.value
                      )
                    }
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Wrong Answer"
                    fullWidth
                    variant="outlined"
                    value={field.input_fields.wrongAnswer || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        field.sequence,
                        "wrongAnswer",
                        e.target.value
                      )
                    }
                    sx={{ marginBottom: 2 }}
                  />
                  {field.input_fields.options?.map((option) => (
                    <Box
                      key={option.id}
                      sx={{ display: "flex", alignItems: "center", gap: 2 }}
                    >
                      <TextField
                        label={`Option ${option.id}`}
                        fullWidth
                        value={option.text}
                        onChange={(e) =>
                          handleOptionChange(
                            field.sequence,
                            option.id,
                            e.target.value
                          )
                        }
                      />
                      <Checkbox
                        checked={field.input_fields.answers?.includes(
                          option.id
                        )}
                        onChange={() =>
                          handleToggleAnswer(field.sequence, option.id)
                        }
                      />
                    </Box>
                  ))}
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => handleAddOption(field.sequence)}
                  >
                    Add Option
                  </Button>
                </>
              )}

              {field.type === "quizFreeText" && (
                <TextField
                  label="Paragraph"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={3}
                  value={field.input_fields.paragraph || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      field.sequence,
                      "paragraph",
                      e.target.value
                    )
                  }
                />
              )}
            </CardContent>
            <CardActions>
              <IconButton
                color="error"
                onClick={() => handleDeleteField(field.sequence)}
              >
                <DeleteIcon />
              </IconButton>
            </CardActions>
          </Card>
        ))}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleUpdate}
        >
          Update
        </Button>

        <Snackbar
          open={!!snackbarMessage}
          autoHideDuration={4000}
          onClose={() => setSnackbarMessage("")}
          message={snackbarMessage}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnackbarMessage("")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </Box>
    </Box>
  );
}
