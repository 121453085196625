import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Switch,
  FormControlLabel,
  Collapse,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
  Snackbar,
  TextField,
  Autocomplete,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  PromptsInterface,
  SingleDescriptionInterface,
  SingleRecipe,
} from "../../../interface/RecipeInterface";
import MacroCard from "../MacroCard";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { recipeService } from "../../../services/recipe.service";
import EmptyMultiRecipeCard from "../Multi/EmptyMultiRecipeCard";
import RecipeImageModal from "../RecipeImageModa";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { accountService } from "../../../services/account.service";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";

function SingleDescription({
  setSingleRecipe,
  singleRecipe,
  singleRecipeLoading,
  setSingleRecipeLoading,
  singleRecipeImageLoading,
  setSingleRecipeImageLoading,
  prompts,
  setPrompts,
  selectedEditingRecipe,
  setSelectedEditingRecipe,
  setRenderMiddleSection,
  updateScreenName,
  setUpdateScreenName,
}: SingleDescriptionInterface) {
  const [viewMode, setViewMode] = useState(false);
  const [openIngredients, setOpenIngredients] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [recipeImageModalOpen, setRecipeImageModalOpen] = useState(false);
  const [recipeId, setRecipeId] = useState("");
  const [recipeImage, setRecipeImage] = useState("");
  const [updateId, setUpdateId] = useState<string>("");
  const [inputValue, setInputValue] = useState("");

  const [storageName, setStorageName] = useState<string | null>(null);
  const [editMode, setEditMode] = useState(false); // New state for edit mode
  const [editedName, setEditedName] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState<any>([]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    setSingleRecipe({
      ...singleRecipe,
      [field]: e.target.value,
    });
  };

  const handleEditClick = () => {
    if (singleRecipe) {
      setEditedName(singleRecipe.name);
      setEditedDescription(singleRecipe.description);
    }

    setEditMode(true);
  };

  const handleSaveClick = () => {
    setSingleRecipe({
      ...singleRecipe,
      name: editedName,
      description: editedDescription,
    });
    setEditMode(false);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedName(e.target.value);
  };

  useEffect(() => {
    async function fetchCategories() {
      try {
        const uniqueCategories = await recipeService.getUniqueCategories();

        setCategoriesList(uniqueCategories.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    }
    fetchCategories();
  }, []);

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedDescription(e.target.value);
  };

  const closeRecipeImageModal = () => {
    setRecipeImageModalOpen(!closeRecipeImageModal);
  };
  const toggleIngredients = () => {
    setOpenIngredients(!openIngredients);
  };

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  /// Function for saving the recipe in the prod database---------------------------------/
  async function addRecipes(dbType: string, recipeDetails: any) {
    if (selectedCategories.length === 0) {
      setSnackbarOpen(true);
      setSnackbarMessage("Categories can't be empty");
    } else {
      setButtonLoading(true);
      try {
        let newRecipeDetails = {
          ...recipeDetails,
          categories: selectedCategories,
        };

        const savedResponse = await recipeService.addRecipes(
          dbType,
          newRecipeDetails
        );

        const { message, success } = savedResponse;
        if (success) {
          setSnackbarMessage(message);
          setSnackbarOpen(true);
          setButtonLoading(false);
        } else {
          setSnackbarMessage(message);
          setSnackbarOpen(true);
          setButtonLoading(false);
        }
      } catch (error) {
        setSnackbarMessage("Some error has happened");
        setSnackbarOpen(true);
        setButtonLoading(false);
      }
    }
  }

  /// Using the useeffect to update the selected recipe if we are trying to edit any recipe from browse section ----/

  useEffect(() => {
    if (selectedEditingRecipe !== null) {
      setSingleRecipe({ ...selectedEditingRecipe });

      if (selectedEditingRecipe._id) {
        if (
          selectedEditingRecipe.categories &&
          selectedEditingRecipe.categories.length > 0
        ) {
          // Set categories as an array for Autocomplete
          setSelectedCategories(selectedEditingRecipe.categories);
        }

        setUpdateId(selectedEditingRecipe._id);
        setSelectedEditingRecipe(null);
      }
    } else {
      setSingleRecipe(null);
      setUpdateScreenName("");
      setSelectedCategories([]); // Clear the categories for Autocomplete

      setUpdateId("");
    }
  }, []);

  /// Funciton for generating new  dallee image only -----------------------------------------------/
  // async function generateDalleImage(){
  //   try {
  //     setSingleRecipeLoading(true);
  //   setSingleRecipeImageLoading(true);
  //   setSingleRecipe(null);
  //   try {
  //     // Retrieve the entire manually typed input including the output structure from values.chatGpt4
  //     let manualPrompt = values.chatGptInput.replace(/\n/g, "\\n");

  //     // Add the logging information at the beginning of the prompt
  //     let fullPrompt = `- Input User query is provided to you as "recipe details"\n${manualPrompt}`;
  //     let dalleImagePrompt = `${values.dalleInput} Recipe Details is ${values.recipeInput}`;
  //     // Store in local storage
  //     localStorage.setItem("singleChatGptPrompt", fullPrompt);
  //     localStorage.setItem("dalleImagePrompt", values.dalleInput);
  //     // Call the ChatGPT API

  //     // Handle the DALL-E response
  //     dalleResponsePromise
  //       .then((dalleResponse) => {
  //         if (dalleResponse && dalleResponse.success) {
  //           console.log("dalle image has been fetched");

  //           setSingleRecipe((prevState: any) => ({
  //             ...prevState,
  //             image: dalleResponse.recipeImage[0], // Assuming dalleResponse has an 'image' property
  //           }));

  //           setSnackbarOpen(true);
  //           setSnackbarMessage("Recipe image fetched successfully!");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching recipe image:", error);
  //         setSnackbarOpen(true);
  //         setSnackbarMessage("Error fetching recipe image.");
  //       })
  //       .finally(() => {
  //         setSingleRecipeImageLoading(false);
  //       });

  //   } catch (error) {
  //       setSingleRecipeImageLoading(false);
  //       setSnackbarOpen(true);
  //       setSnackbarMessage("Unable to generate new image");
  //   }
  // }

  async function generateDalleImage(prompts: PromptsInterface) {
    setSingleRecipeImageLoading(true);
    try {
      let dalleImagePrompt = `${prompts.dalle} Recipe Details is ${prompts.recipeDetails}`;
      let promptValue = [];
      promptValue.push(dalleImagePrompt);
      localStorage.setItem("dalleImagePrompt", prompts.dalle);
      const getDalleImage = await recipeService.getImageForDalle(
        promptValue,
        "single"
      );
      setSingleRecipe((prevState: SingleRecipe) => ({
        ...prevState,
        image: getDalleImage.recipeImage[0], // Assuming dalleResponse has an 'image' property
      }));
      setSingleRecipeImageLoading(false);
      setSnackbarOpen(true);
      setSnackbarMessage("New recipe image has been generated");
    } catch (error) {
      setSingleRecipeImageLoading(false);
      setSnackbarOpen(true);
      setSnackbarMessage("Unable to generate new image");
    }
  }

  /// Function for generating new recipe details ---------------------------------------------------------/
  async function generateNewRecipeDetails(prompts: PromptsInterface) {
    setSingleRecipeLoading(true);

    try {
      let gptPrompt = `- Input User query is provided to you as "recipe details"\n${prompts.gpt}`;
      localStorage.setItem("singleChatGptPrompt", gptPrompt);
      const getGptResponse = await recipeService.getSingleRecipesFromAi(
        gptPrompt,
        prompts.recipeDetails
      );
      setSingleRecipe((prevState: SingleRecipe) => ({
        ...prevState,
        ...getGptResponse.recipeDetails,
      }));
      setSingleRecipeLoading(false);
      setSnackbarOpen(true);
      setSnackbarMessage("New recipe  has been generated");
    } catch (error) {
      setSingleRecipeImageLoading(false);
      setSnackbarOpen(true);
      setSnackbarMessage("Unable to generate new recipe");
    }
  }
  //// Funciton for opening up the image modal ----------------------------------------------------/
  function openImage(recipeId: string, recipeImage: string) {
    setRecipeId(recipeId);
    setRecipeImageModalOpen(!recipeImageModalOpen);
    setRecipeImage(recipeImage);
  }

  /// Funciton for updating an existing recipe ---------------------------------------------------/
  async function updateRecipe() {
    if (selectedCategories.length === 0) {
      setSnackbarMessage("Categories can't be empty");
      setSnackbarOpen(true);
    } else {
      let updatedRecipe = {
        ...singleRecipe,
        id: updateId,
        categories: selectedCategories, // Use the selectedCategories array directly
      };

      setButtonLoading(true);
      try {
        const updateResponse = await recipeService.updateRecipe(
          updateScreenName,
          updatedRecipe
        );

        if (updateResponse.success) {
          setSnackbarMessage("Recipe has been updated");
          setSnackbarOpen(true);
          setButtonLoading(false);
          setRenderMiddleSection(updateScreenName);
        } else {
          setSnackbarMessage(`${updateResponse.message}`);
          setSnackbarOpen(true);
          setButtonLoading(false);
        }
      } catch (error) {
        setSnackbarMessage("Some error has happened");
        setSnackbarOpen(true);
        setButtonLoading(false);
      }
    }
  }

  async function fetchStorageName() {
    const storageNameResponse = await accountService.getStorageName();
    setStorageName(storageNameResponse.storageName);
  }
  useEffect(() => {
    fetchStorageName();
  }, []);
  return (
    <>
      <Box
        sx={{
          margin: "auto",
          overflowX: "hidden",
          p: 2,
        }}
      >
        <Container
          sx={{
            position: "relative",
            width: "100%",

            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={viewMode}
                onChange={() => setViewMode(!viewMode)}
                color="primary"
              />
            }
            label={viewMode ? "Raw" : "Formatted"}
            labelPlacement="start"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              "& .MuiFormControlLabel-label": {
                color: viewMode ? "primary.main" : "text.primary",
                fontWeight: "bold",
              },
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {singleRecipeImageLoading ? (
              <Container>
                <CircularProgress />
              </Container>
            ) : (
              <>
                {singleRecipe !== null ? (
                  <Box
                    sx={{
                      height: { md: 170, xs: 100 },
                      display: "flex",
                      maxHeight: 200,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        singleRecipe.image
                          ? singleRecipe.image
                          : `https://${storageName}.blob.core.windows.net/reshape-public/recipes/images/saved/${singleRecipe.id}.png`
                      }
                      alt="Recipe"
                      style={{
                        width: "30%",
                        borderRadius: 4,
                        height: "auto", // Maintain aspect ratio
                        cursor: "pointer",
                      }}
                      loading="lazy"
                      onClick={() =>
                        openImage(singleRecipe.id, singleRecipe.image)
                      }
                    />

                    <Box
                      sx={{
                        height: "100%",

                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <RefreshIcon
                        sx={{ cursor: "pointer", marginBottom: 2 }}
                        onClick={() => generateDalleImage(prompts)}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Typography></Typography>
                )}
              </>
            )}
          </Box>
        </Container>
        {singleRecipeLoading ? (
          <Container
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginTop: 30,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Container>
        ) : (
          <>
            {singleRecipe !== null ? (
              <>
                {" "}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {editMode ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginTop: 5,

                        width: "100%",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={editedName}
                        onChange={handleNameChange}
                      />
                      <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        value={editedDescription}
                        onChange={handleDescriptionChange}
                        sx={{
                          marginTop: 2,
                        }}
                      />
                    </Box>
                  ) : (
                    <Container
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginTop: 5,
                      }}
                    >
                      <Typography
                        variant="h4"
                        component="h4"
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        {singleRecipe.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          marginTop: 3,
                        }}
                      >
                        {singleRecipe.description}
                      </Typography>
                    </Container>
                  )}
                  {viewMode ? null : editMode ? (
                    <IconButton onClick={handleSaveClick}>
                      <CheckIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={handleEditClick}>
                      <EditIcon />
                    </IconButton>
                  )}
                </Box>
                {viewMode === false ? (
                  <>
                    <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                      Nutrition Per Serving
                    </Typography>
                    <Box
                      flexWrap="wrap"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <MacroCard
                        label={"Calories"}
                        value={singleRecipe.calories}
                        editMode={editMode}
                        handleInputChange={handleInputChange}
                      />
                      <MacroCard
                        label={"Protein"}
                        value={singleRecipe.protein}
                        editMode={editMode}
                        handleInputChange={handleInputChange}
                      />
                      <MacroCard
                        label={"Fat"}
                        value={singleRecipe.fats}
                        editMode={editMode}
                        handleInputChange={handleInputChange}
                      />
                      <MacroCard
                        label={"Carbs"}
                        value={singleRecipe.carbs}
                        editMode={editMode}
                        handleInputChange={handleInputChange}
                      />
                      <MacroCard
                        label={"Fiber"}
                        value={singleRecipe.fiber}
                        editMode={editMode}
                        handleInputChange={handleInputChange}
                      />
                      <MacroCard
                        label={"Added Sugar"}
                        value={singleRecipe.added_sugar}
                        editMode={editMode}
                        handleInputChange={handleInputChange}
                      />
                    </Box>

                    <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                      Ingredients
                    </Typography>
                    <List
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                      sx={{
                        width: "100%",
                        maxHeight: "100px", // Sets a max height for the list
                        overflowY: "auto", // Makes the list scrollable
                        bgcolor: "background.paper",
                        border: "1px solid #ccc", // Adds a border around the list
                        borderRadius: "4px", // Rounds the corners of the list container
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Adds subtle shadow for depth
                      }}
                    >
                      <ListItem button onClick={toggleIngredients}>
                        <ListItemText primary="Ingredients Details" />
                        {openIngredients ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </ListItem>
                      <Collapse
                        in={openIngredients}
                        timeout="auto"
                        unmountOnExit
                      >
                        <TableContainer>
                          <Table
                            stickyHeader
                            aria-label="nutritional information table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Ingredient</TableCell>
                                <TableCell align="center">Calories</TableCell>
                                <TableCell align="center">Protein</TableCell>
                                <TableCell align="center">Carbs</TableCell>
                                <TableCell align="center">Fat</TableCell>
                                <TableCell align="center">Quantity</TableCell>
                                <TableCell align="center">Sugar</TableCell>
                                <TableCell align="center">Fiber</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {singleRecipe.ingredients &&
                                singleRecipe.ingredients.length > 0 &&
                                singleRecipe.ingredients.map(
                                  (ingredient, index) => (
                                    <TableRow key={index}>
                                      <TableCell component="th" scope="row">
                                        {ingredient.name}
                                      </TableCell>
                                      <TableCell align="center">
                                        {ingredient.calories} kcal
                                      </TableCell>
                                      <TableCell align="center">
                                        {ingredient.protein} g
                                      </TableCell>
                                      <TableCell align="center">
                                        {ingredient.carbs} g
                                      </TableCell>
                                      <TableCell align="center">
                                        {ingredient.fats} g
                                      </TableCell>
                                      <TableCell align="center">
                                        {ingredient.qty} {ingredient.unit}
                                      </TableCell>
                                      <TableCell align="center">
                                        {ingredient.added_sugar} g
                                      </TableCell>
                                      <TableCell align="center">
                                        {ingredient.fiber} g
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Collapse>
                    </List>

                    <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                      Directions
                    </Typography>
                    {editMode == true ? (
                      <TextField
                        value={singleRecipe.direction}
                        onChange={(e) => handleInputChange(e, "direction")}
                        fullWidth={true}
                        multiline={true}
                      />
                    ) : (
                      <Typography sx={{ mb: 2 }}>
                        {singleRecipe.direction}
                      </Typography>
                    )}
                    <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                      Duration :
                    </Typography>
                    {editMode == true ? (
                      <TextField
                        sx={{ mb: 2 }}
                        value={singleRecipe.duration}
                        onChange={(e) => handleInputChange(e, "duration")}
                        fullWidth={true}
                      />
                    ) : (
                      <Typography sx={{ mb: 2 }}>
                        {singleRecipe.duration}
                      </Typography>
                    )}
                    <Autocomplete
                      multiple
                      freeSolo
                      options={categoriesList}
                      value={selectedCategories}
                      inputValue={inputValue} // Track the current input value
                      onInputChange={(event, newInputValue) => {
                        // Check if input includes a comma
                        if (newInputValue.includes(",")) {
                          const newCategory = newInputValue
                            .replace(",", "")
                            .trim(); // Remove the comma and trim spaces

                          // Only add the new category if it's not empty and not already added
                          if (
                            newCategory &&
                            !selectedCategories.includes(newCategory)
                          ) {
                            setSelectedCategories((prevCategories: any) => [
                              ...prevCategories,
                              newCategory,
                            ]);
                          }

                          // Clear the input field after storing the value
                          setInputValue(""); // Clear the input value state
                        } else {
                          // Update the inputValue with the current input if no comma is detected
                          setInputValue(newInputValue);
                        }
                      }}
                      onChange={(event, newValue: any) => {
                        setSelectedCategories(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Categories"
                          placeholder="Select or type categories"
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                      )}
                    />
                  </>
                ) : (
                  <Typography sx={{ mt: 2 }}>
                    Raw Data: {JSON.stringify(singleRecipe, null, 2)}
                  </Typography>
                )}
                {updateId ? (
                  <>
                    {buttonLoading ? (
                      <Container
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress />
                      </Container>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => updateRecipe()}
                          disabled={singleRecipe.length === 0}
                        >
                          Update
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {buttonLoading ? (
                      <Container
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress />
                      </Container>
                    ) : (
                      <>
                        {" "}
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ mr: 1 }}
                          onClick={() => addRecipes("saved", singleRecipe)}
                          disabled={singleRecipe.length === 0}
                        >
                          Add to Saved
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => addRecipes("prod", singleRecipe)}
                          disabled={singleRecipe.length === 0}
                        >
                          Add to Prod
                        </Button>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <Box sx={{ marginTop: 10 }}>
                  <EmptyMultiRecipeCard />
                </Box>
              </>
            )}
          </>
        )}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2", // Use primary color from the theme
            color: "white", // Set text color to white
          },
        }}
      />
      <RecipeImageModal
        recipeImageModalOpen={recipeImageModalOpen}
        closeRecipeImageModal={closeRecipeImageModal}
        recipeId={recipeId}
        setRecipeImageModalOpen={setRecipeImageModalOpen}
        recipeImage={recipeImage}
        storageName={storageName}
      />
    </>
  );
}

export default SingleDescription;
